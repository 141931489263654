import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 0 20px 60px;

  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    margin: 48px 0 16px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 40px;
  }

  .btn {
    background: #FFFFFF;
    border: 1px solid #3372F0;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #3372F0;
    width: 300px;
    height: 48px;
  }

  .inner-wrapper {
    color: #000;
    position: relative;
    justify-content: center;
    text-align: center;
    min-height: 400px;
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    padding-bottom: 120px;
    background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, #F4F4F4 100%);

    .inner-wrapper {
      padding: 59px 95px;
    }
  }

  @media (min-width: 992px) {
    .wrapper {
      max-width: 900px;
    }
  }
`;
