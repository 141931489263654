import { Wrapper } from "./StaticPage.styles";
import Layout from "../containers/Layout";
import gincai from "./static/gincai.html.js";
import taisykles from "./static/taisykles.html.js";
import privatumoPolitika from "./static/privatumo-politika.html.js";
import slapukuPolitika from "./static/slapuku-politika.html";
import bendrosiosSalygos from "./static/bendrosios-salygos.html";
import { useLocation } from "react-router-dom";
import useTitle from "../hooks/useTitle";

const urlContentMap = {
  "gincai-su-rinkos-dalyviu": {
    content: gincai,
    title: "Ginčai su rinkos dalyviu",
  },
  taisykles: {
    content: taisykles,
    title: "Tinklalapio taisyklės",
  },
  "privatumo-politika": {
    content: privatumoPolitika,
    title: "Privatumo politika",
  },
  "bendrosios-salygos": {
    content: bendrosiosSalygos,
    title: "Bendrosios sąlygos",
  },
  "slapuku-politika": {
    content: slapukuPolitika,
    title: "Slapukų politika",
  },
};

function StaticPage() {
  const location = useLocation();
  const pageUrlParts = location.pathname.split("/");
  const url = decodeURIComponent(pageUrlParts[1]);

  useTitle(`${urlContentMap[url].title} | Rego`);

  return (
    <Layout theme="dark">
      <Wrapper>
        <div className="wrapper">
          <div className="row">
            <div className="col">
              <div className="inner-wrapper">
                <div
                  dangerouslySetInnerHTML={{
                    __html: urlContentMap[url].content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
}

export default StaticPage;
