import t from "../localizations/index";
import { Button, Container, Message, Subtitle } from "./ServiceDisabledView.styles";

const ServiceDisabledView = () => {
  return (
    <Container>
      <h1>Saugi paskola sąžiningomis palūkanomis</h1>
      <Subtitle
        dangerouslySetInnerHTML={{
          __html: "Užpildykite užklausos formą ir su Jumis per 1 d.d. susisieks vadybininkas.",
        }}
      />
      <Message>Paslauga laikinai neteikiama.</Message>
      <Button type="button" disabled>
        GAUTI PASIŪLYMĄ
      </Button>
    </Container>
  );
};

export default ServiceDisabledView;
