import Home from "./pages/home/Home";
import NotFound from "./pages/NotFound";
import StaticPage from "./pages/StaticPage";
import { Route, Routes } from "react-router-dom";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/gincai-su-rinkos-dalyviu" element={<StaticPage />} />
      <Route path="/privatumo-politika" element={<StaticPage />} />
      <Route path="/slapuku-politika" element={<StaticPage />} />
      <Route path="/taisykles" element={<StaticPage />} />
      <Route path="/bendrosios-salygos" element={<StaticPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
