import isEmail from "validator/lib/isEmail";
import t from "../localizations/index";
import { isPossiblePhoneNumber } from "libphonenumber-js";

export const requiredRule = { required: { value: true, message: "Prašome užpildyti šį laukelį" } };

export const calculateAnnuity = ({ monthsCount, interest, amount }) => {
  const monthlyInterest = interest / 100 / 12;
  const pow = Math.pow(monthlyInterest + 1, -monthsCount);
  const annuity = (monthlyInterest / (1 - pow)) * amount;
  return Math.round(annuity);
};

export const emailRule = {
  ...requiredRule,
  validate: (value) => {
    if (value) {
      return isEmail(value) ? undefined : t.msg_error_field_email;
    }
  },
};

export const mobilePhoneRule = {
  ...requiredRule,
  validate: (value) => {
    const isPhoneNumber = value[0] !== "+" ? isPossiblePhoneNumber(`+${value}`) : isPossiblePhoneNumber(value);
    return isPhoneNumber ? undefined : t.msg_error_field_phone_number;
  },
};

export const getMinRule = (value) => ({
  min: { value, message: `Negali būti mažiau nei ${value}` },
});

export const getMaxRule = (value) => ({
  max: { value, message: `Negali būti daugiau nei ${value}` },
});

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export const THOUSAND_SPLIT_REGEX = /\B(?=(\d{3})+(?!\d))/g;

export const formatNumber = (value) => {
  return value && value.toString().replace(THOUSAND_SPLIT_REGEX, " ");
};

export const isDefined = (value) => {
  return value !== undefined && value !== null;
};
