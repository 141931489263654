import styled from "@emotion/styled";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 15px;
  padding: 50px 30px 35px 30px;
  width: 100%;
  position: relative;

  @media (min-width: 576px) {
    width: 507px;
  }
  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #000000;
  }
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const Message = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding: 117px 0;
  text-align: center;
`;
export const Button = styled.button`
  background-color: #f6f6f6;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #b5b5b4;
  padding: 0;
  height: 50px;
  display: block;
  width: 100%;
  text-transform: uppercase;
  border: none;
  outline: none;
`;
