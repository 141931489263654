import styled from "@emotion/styled";

export const CheckmarkStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  transition: 200ms all;
  border: 1px solid ${(props) => (props.isLabelHovered || props.isChecked ? "#3f8efc" : "gray")};
  border-color: ${(props) => (props.isInvalid ? "red" : "")};
  flex-shrink: 0;
  padding: 2px;
  background-color: ${(props) => (props.isChecked ? "#3f8efc" : "")};
`;
