import styled from "@emotion/styled";

export const Label = styled.label`
  flex-direction: column;
  color: #333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  display: block;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  display: block;
  height: 55px;
  width: 100%;
  border: 1px solid #3d3d3d;
  border-radius: 4px;
  padding: 0 8px;
`;
