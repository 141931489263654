import styled from "@emotion/styled";

export const FormControl = styled.div`
  margin-bottom: 15px;
`;

export const Modal = styled.div`
  .modal-header {
    border: none;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
  }
  .page-title {
    text-align: center;
    margin-bottom: 40px;
  }
  .center {
    text-align: center;
  }
  .modal-content {
    height: 100vh;
    overflow: auto;
    &.height-auto {
      height: auto;
    }
  }
  table {
    margin: 20px auto 0;
    width: 100%;
  }
  table td {
    vertical-align: top;
  }
  .modal-body {
    max-width: 660px;
    margin: 0 auto 40px;
    z-index: 1;
  }
  .btn-close:focus {
    box-shadow: none;
  }
`;
