import { memo } from "react";
import { HeaderStyled } from "./Header.styles";
import { Link } from "react-router-dom";

function Header({ theme = "light" }) {
  return (
    <HeaderStyled>
      <div className="row">
        <div className="col d-flex justify-content-between align-items-center">
          <Link to="/">
            <img src={`./assets/images/logo${theme === "dark" ? "-dark" : ""}.svg`} className="logo" alt="logo" />
          </Link>
        </div>
      </div>
    </HeaderStyled>
  );
}

export default memo(Header);
