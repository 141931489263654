import { memo } from "react";
import { AccordionHeading, AccordionItem } from "./Accordion.styles";

export default memo(function Accordion({ list, id }) {
  const rendeItems = () => {
    return list.map((item, i) => {
      return (
        <AccordionItem className="accordion-item" key={item.title}>
          <AccordionHeading className="accordion-heading" id={`heading${i}`}>
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${i}`}
              aria-controls={`collapse${i}`}
            >
              <span className="title">{item.title}</span>
              <span className="icon-label" />
            </button>
          </AccordionHeading>
          <div id={`collapse${i}`} className="accordion-collapse collapse" aria-labelledby={`heading${i}`}>
            <div className="accordion-body">
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          </div>
        </AccordionItem>
      );
    });
  };

  return (
    <div className="accordion" id={id}>
      {rendeItems()}
    </div>
  );
});
