import styled from "@emotion/styled";

export const Hero = styled.div`
  background-color: #544b46;
  padding-top: 100px;
  padding-bottom: 45px;
  padding-left: 0px;
  min-height: 780px;

  .wrapper {
    width: 90%;
    margin: 0 auto;
  }

  @media (min-width: 576px) {
    background-image: url("./assets/images/hero-bg.jpg");
    background-attachment: fixed;
    background-size: cover;
    padding-right: 20px;
  }
`;

export const FormWrapper = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 50px 30px 35px 30px;
  width: 100%;
  position: relative;

  @media (min-width: 576px) {
    width: 507px;
  }

  .img-stamp {
    display: none;
  }

  @media (min-width: 768px) {
    .img-stamp {
      right: -122px;
      bottom: 60px;
      position: absolute;
      display: block;
    }
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 16px;
    word-spacing: -1px;
    color: #444;
  }

  .subtitle {
    font-weight: 300;
    font-size: 17px;
    font-family: "Open Sans", sans-serif;
    line-height: 27px;
    margin-bottom: 60px;
    word-spacing: -1px;
    color: #3d3d3d;
  }
  .input-block {
    margin-bottom: 15px;
    position: relative;

    .tooltip-wrapper {
      display: inline-block;
      &:hover > .tooltip-2 {
        display: block;
      }
    }

    .info-icon {
      width: 16px;
      height: 16px;
      vertical-align: -4px;
      margin-left: 5px;
    }

    .tooltip-2 {
      font-size: 11px;
      position: absolute;
      z-index: 1070;
      display: none;
      background-color: #eee;
      padding: 8px 12px;
      border-radius: 3px;
      top: -40px;
      left: 0;

      &:before {
        content: "";
        position: absolute;
        top: 32px;
        left: ${({ locale }) => `${locale === "lv" ? "135px" : "176px"}`};
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0px 6px;
        border-color: #eee transparent transparent transparent;
        z-index: 12;
      }
    }
  }
  .term-wrapper {
    margin-top: 20px;
  }
  .monthly-income-wrapper {
    margin-top: 30px;
  }

  .monthly-income-label {
    color: #444;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 576px) {
      line-height: 33px;
    }
  }
  .monthly-income-amount {
    color: #3f8efc;
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    white-space: nowrap;
    @media (min-width: 576px) {
      line-height: 33px;
    }
  }
  .monthly-income-note-text {
    margin-top: 4px;
    opacity: 0.54;
    color: #333;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }

  label {
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0;
    &.amount-label {
      display: inline-block;
    }
  }
  .submit-button {
    padding: 0;
    height: 50px;
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #3f8efc;
    color: #fff;
    border-radius: 40px;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    outline: none;
    &:hover {
      color: #fff;
      background-color: #0b5ed7;
      border-color: #0a58ca;
    }
    &.submiting {
      background-color: #aaa;
      &:hover {
        cursor: not-allowed;
        background-color: #aaa;
      }
    }
  }
`;

export const Partners = styled.div`
  color: #000;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid #ccc;

  .wrapper {
    width: 90%;
    margin: 0 auto;
  }

  ul {
    width: 95%;
    margin: 0 auto;
    padding: 20px 0 20px;
    list-style: none;

    li {
      width: 49%;
      display: inline-block;
      img {
        max-width: 150px;
        padding: 13px;
        @media (min-width: 576px) {
          padding: 0;
        }
      }
    }
  }

  .credit-info-img {
    max-height: 50px;
  }

  @media (min-width: 576px) {
    text-align: left;
    ul {
      flex-direction: row;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        margin: 0 15px;
        flex: 1;
      }
    }
  }
`;

export const Section = styled.div`
  text-align: center;
  padding-top: 160px;
  padding-bottom: 130px;
  margin-bottom: 5px;

  &.faq {
    padding-top: 40px;
    padding-bottom: 0;
  }

  #faq-accordion {
    max-width: 860px;
    margin: 0 auto;
    padding-top: 50px;

    .title {
      font-size: 18px;
      font-weight: 400;
    }
    .accordion-heading {
      &:hover .title {
        text-decoration: underline;
      }
    }
    .accordion-body {
      letter-spacing: 0px;
      font-weight: 300;
      font-family: "Open Sans",sans-serif;
      font-style: normal;
      color: #3d3d3d;
      font-size: 18px;
    }
    .accordion-item {
      border-bottom: 1px solid;
      border-radius: 0;
      padding-bottom: 10px;
    }
    .accordion-item:last-child {
      border: none;
    }
  }

  .col {
    img {
      margin: 30px auto;
      display: block;
    }
    .title {
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0px;
    }
    .text {
      font-weight: 400;
      width: 75%;
      font-size: 14px;
      line-height: 22px;
      padding: 10px 0 25px 0;
      color: rgba(37,37,37,0.7);
      margin: 0 auto;
      font-family: "Open Sans",sans-serif;
    }
  }

  &.easyLoan {
    padding-top: 100px;
    padding-bottom: 30px;
    .wrapper > .title {
      padding-bottom: 50px;
    }
    .col {
      img {
        width: 142px;
      }
    }
  }

  &.moreThan {
    .row {
      padding-top: 50px;
    }
    img {
      width: 100%;
      margin: 0 auto 30px;
      display: block;
      @media (min-width: 576px) {
        max-width: 500px;
      }
    }
    .col {
      img {
        width: 55px;
      }
    }
  }

  &.blockquote {
    img {
      width: 100%;
    }
    .name {
      font-size: 18px;
      font-weight: 400;
      font-family: "Open Sans",sans-serif;
    }
  }
  @media (min-width: 576px) {
    &.blockquote {
      img {
        width: 340px;
      }
    }
  }

  blockquote {
    font-size: 42px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2em;
    max-width: 840px;
    font-family: "Open Sans",sans-serif;
    margin: 0 auto 50px;
  }

  .wrapper {
    max-width: 90%;
    margin: 0 auto;
  }

  .image {
    margin-top: 80px;

    img {
      width: 100%;
    }
    @media (min-width: 576px) {
      img {
        width: 310px;
      }
    }
  }

  .images {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 139px;
      margin: 20px 0;
    }
    @media (min-width: 576px) {
      flex-direction: row;
      img {
        margin: 0 50px;
      }
    }
  }

  p {
    color: #3d3d3d;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    margin: 0 auto 50px;
    max-width: 860px;
    font-family: "Open Sans",sans-serif;
  }

  .cta-btn {
    color: #3f8efc;
    background-color: transparent;
    border-radius: 30px;
    padding: 13px 26px;
    display: block;
    max-width: 300px;
    text-decoration: none;
    font-weight: 500;
    margin: 0 auto;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.5em;
    border: 2px solid #3f8efc;
    transition: background-color .25s,border-color .25s;

    &:hover {
      color: #fff;
      background-color: #0b5ed7;
      border-color: #0a58ca;
    }
`;

export const CallToAction = styled.div`
  background: #F8F8F8;
  text-align: center;
  padding-top: 160px;
  padding-bottom: 130px;
  margin-bottom: 5px;

  .wrapper {
    width: 90%;
    margin: 0 auto;
  }

  .cta-btn {
    background-color: #3f8efc;
    border-color: #3f8efc;
    color: #fff;
    border-radius: 30px;
    padding: 13px 26px;
    text-decoration: none;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.5em;
    transition: background-color .25s, border-color .25s;

    &:hover {
      color: #fff;
      background-color: #0b5ed7;
      border-color: #0a58ca;
    }
`;

export const Title = styled.h3`
  color: rgb(20, 20, 20);
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2em;
  height: auto;
  max-width: calc(100% - 20px);
  margin: 0 auto 50px;
  position: relative;

  &.decorated:after {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    border-top: 2px solid rgb(85, 193, 249);
  }

  &.decorated.black:after {
    border-color: #000;
  }
  @media (min-width: 576px) {
    max-width: 860px;
  }
`;
