import styled from '@emotion/styled'

export const AccordionHeading = styled.h2`
  margin-bottom: 0;

  .accordion-button {
    border: none;
    border-radius: 10px;
    background-color: #fff;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    line-height: 158.5%;
    outline: 0;
    box-shadow: none;
    position: relative;
  }
  .icon-label {
    display: none;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-button::after {
    background-position: center;
    background-size: 14px 9px;
    position: absolute;
    right: 0;
  }
  .accordion-button::after, .accordion-button:not(.collapsed)::after {
    background-image: url('./assets/images/angle-down.png');
  }
  @media (min-width: 576px) {
    .icon-label {
      width: 70px;
    }
    .icon-label::after {
      content: "Suskleisti";
      position: absolute;
      right: 48px;
      top: 50%;
      transform: translateY(-50%);
      color: #3F8EFE;
    }
    .collapsed .icon-label::after {
      content: "Skaityti";
    }
  }
`;

export const AccordionItem = styled.div`
  border: none;
  border-radius: 10px;
  margin-bottom: 8px;
  &:last-of-type .accordion-button.collapsed, &:last-of-type .accordion-button {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:first-of-type .accordion-button.collapsed, &:first-of-type .accordion-button {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  &:first-of-type {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  &:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .accordion-body {
    font-size: 14px;
    line-height: 22px;
    color: #808080;
    padding-top: 0;
    text-align: left;
  }
  @media (min-width: 992px) {
    margin-bottom: 15px;
  }
`;

