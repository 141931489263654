const file = `
<h3 class="page-title">Pažeidė jūsų teises?</h3>
<p>Jei manote, kad REGO.LT pažeidė Jūsų teises, per tris mėnesius galite pateikti skundą. Skundas turi būti parašytas valstybine kalba, įskaitomu raštu, pasirašytas pareiškėjo. </p>
<p>Skunde privalote nurodyti:</p>
<ul>
<li>Savo vardą ir pavardę;</li>
<li>Adresą;</li>
<li>Asmens kodą arba gimimo datą, </li>
<li>Elektroninio pašto adresą;</li>
<li>Aplinkybes ir dokumentus, kuriais remiantis pateikiate skundą.</li>
</ul>
<p>Skundą galima pateikti elektroniniu paštu: <a href="mailto:info@rego.lt">info@rego.lt</a></p>
<p>Skundai nagrinėjami neatlygintinai. Skundas turi būti išnagrinėtas per 15 (penkiolika) darbo dienų. Jei išskirtiniu atveju skundas negali būti išnagrinėtas laiku, paskirtas darbuotojas privalo apie tai informuoti pareiškėją.  Visais atvejais skundo nagrinėjimas negali užtrukti ilgiau nei 35 darbo dienas. Apie skundo nagrinėjimo metu priimtą sprendimą pareiškėjas informuojamas raštu. Atsakymą pasirašo įmonės vadovas arba skundą nagrinėjęs darbuotojas.</p>
<p>Informuojame, kad vartotojas, gavęs jo netenkinantį atsakymą, turi teisę kreiptis į Lietuvos banką (Žalgirio g. 90, LT-09303, Vilnius, Lietuva) raštu arba elektroniniu būdu per vienerius metus po kreipimosi į vartojimo kredito davėją.</p>
<p>Daugiau informacijos apie ginčų nagrinėjimą Lietuvos banke galite rasti Lietuvos banko interneto svetainėje: <a href="http://www.lb.lt/lt/vartotoju-ir-finansu-rinkos-dalyviu-gincai" target="_blank">http://www.lb.lt/lt/vartotoju-ir-finansu-rinkos-dalyviu-gincai</a></p>
<p>Tuo atveju, kai į bendrovę kreipiasi pareiškėjas, kuris nėra vartotojas, Lietuvos banko patvirtintos „Vartotojų ir finansų rinkos dalyvių ginčų neteisminio sprendimo procedūros Lietuvos banke taisyklės” nėra taikomos. Minėtu atveju bendrovė išnagrinėja skundą ir atsakymą pareiškėjui pateikia per 15 (penkiolika) darbo dienų nuo skundo gavimo dienos, tačiau ne vėliau kaip per 30 darbo dienų.</p>
`;

export default file;
