import { memo } from "react";
import { FooterStyled } from "./Footer.styles";
import translations from "../localizations/index";

function Footer() {
  return (
    <FooterStyled>
      <div className="wrapper">
        <div className="row">
          <div className="col-12 column-1 col-md-4">
            <h3 className="title">{translations.footer.col1.title}</h3>
            <p className="paragraph">{translations.footer.col1.paragraph}</p>
          </div>
          <div className="col-12 column-2 col-md-4">
            <div>
              <h3 className="title">{translations.footer.col2.title}</h3>
              <p className="paragraph">
                <span dangerouslySetInnerHTML={{ __html: translations.footer.col2.paragraph }} />
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 column-3">
            <div>
              <h3 className="title">{translations.footer.col3.title}</h3>
              <ul className="more-menu">
                <li>
                  <a href={`${translations.footer.menu.disputes.link}`}>{translations.footer.menu.disputes.text}</a>
                </li>
                <li>
                  <a href={`${translations.footer.menu.privacyPolicy.link}`}>
                    {translations.footer.menu.privacyPolicy.text}
                  </a>
                </li>
                <li>
                  <a href={`${translations.footer.menu.terms.link}`}>{translations.footer.menu.terms.text}</a>
                </li>
                <li>
                  <a href={`${translations.footer.menu.generalConditions.link}`}>
                    {translations.footer.menu.generalConditions.text}
                  </a>
                </li>
                <li>
                  <a href={`${translations.footer.menu.cookiesPolicy.link}`}>
                    {translations.footer.menu.cookiesPolicy.text}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </FooterStyled>
  );
}

export default memo(Footer);
