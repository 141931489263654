import styled from "@emotion/styled";

export const Wrapper = styled.div`
  background: #fff;
  padding-bottom: 60px;
  padding-top: 100px;
  .page-title {
    text-align: center;
    margin-bottom: 40px;
  }
  .center {
    text-align: center;
  }

  .paragraph-title {
    font-size: 18px;
  }
  table {
    margin-bottom: 20px;
  }
  td,
  th {
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
    font-size: 14px;
  }
  th {
    background-color: #eee;
  }
  .text-align-left {
    text-align: left;
  }

  .inner-wrapper {
    color: #000;
    position: relative;
    min-height: 400px;
  }
  .wrapper {
    padding: 0 20px;
  }

  @media (min-width: 768px) {
    padding-bottom: 120px;
    background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, #f4f4f4 100%);

    .wrapper {
      max-width: 680px;
      padding: 0;
      margin: 0 auto;
    }
    .inner-wrapper {
      background: #ffffff;
      border: 1px solid #f4f4f4;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      padding: 56px 56px;
    }
  }
  @media (min-width: 992px) {
    .wrapper {
      max-width: 900px;
    }
  }
`;
