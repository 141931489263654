const file = `
<h3 class="page-title">VARTOJIMO KREDITO SUTARTIES 
BENDROSIOS SĄLYGOS
</h3>

<style>
  ol {
    counter-reset: item;
  }

  ol > ::marker {
    font-weight: 500;
    font-size: 18px;
  }

  ol > li {
    counter-increment: item;
  }

  ol ol > li {
    display: block;
  }

  ol ol > li:before {
    content: counters(item, ".") ". ";
    margin-left: -15px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0 10px;
  }
</style>
<ol>
  <li>
  <h3 class="title">SĄVOKOS</h3>
    <ol>
      <li>
        Šiose Bendrosiose sąlygose didžiąja raide rašomos sąvokos turi žemiau nurodytas reikšmes:
        <ol>
          <li class="c6 li-bullet-1">
            <span class="c3">Sutartis</span
            ><span class="c0"
              >&nbsp;– vartojimo kredito sutartis, kurią sudaro šios Bendrosios sąlygos ir Specialiosios sąlygos,
              Kredito gavėjo pateikta Paraiška, Standartinė informacija, įskaitant visi šios Sutarties vėlesni
              papildymai ir (ar) pakeitimai;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Bendrosios sąlygos</span><span class="c0">&nbsp;– </span
            ><span class="c1">šios bendrosios sąlygos, kurios skelbiamos Kredito gavėjo internetinėje svetainėje </span
            ><span class="c1">https://www.rego.lt/</span
            ><span class="c1"
              >&nbsp;ir yra neatskiriama Sutarties dalis, kurioje nustatomos Šalių teisės ir pareigos, Vartojimo kredito
              suteikimo ir grąžinimo tvarka, Vartojimo kredito sutarties nevykdymo teisinės pasekmės, Vartojimo kredito
              sutarties nutraukimo tvarka ir kitos sąlygos</span
            ><span class="c0">;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Specialiosios sąlygos</span
            ><span class="c0"
              >&nbsp;– neatskiriama Sutarties dalis, kurioje nustatomos Šalių sulygtos Vartojimo kredito teikimo
              sąlygos, atitinkančios individualius Kredito gavėjo Paraiškoje nurodytus jo poreikius, įskaitant Bendra
              vartojimo kredito suma, Palūkanų norma, Vartojimo kredito terminas, Bendrą vartojimo kredito kaina</span
            ><span class="c1">&nbsp;ir jos metinė norma (MN)</span><span class="c0">, Bendr</span
            ><span class="c1">a</span
            ><span class="c0">&nbsp;Kredito gavėjo mokama suma bei kitos esminės sąlygos;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Kredito davėjas </span
            ><span class="c0"
              >– &nbsp;REGO.LT, UAB, juridinio asmens kodas 305864279, buveinės adresas Savanorių pr. 1-217, 03116
              Vilnius</span
            ><span class="c1">;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c16">Kredito davėjo tarpininkas </span
            ><span class="c1"
              >– į Kredito davėjo priklausomų tarpininkų sąrašą įrašytas Kredito davėjo tarpininkas, nurodytas
              Specialiosiose sąlygose, kuris pateikia pasiūlymą Kredito gavėjui sudaryti vartojimo kredito sutartį,
              sudaro galimybes Kredito davėjui elektroniniu būdu pateikti Paraišką ir padeda Kredito gavėjui atlikti
              kitus su Vartojimo kredito gavimu susijusius veiksmus;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Kredito gavėjas </span
            ><span class="c0">– Specialiosiose sąlygose nurodytas Kredito gavėjas;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c16">Tinklapis </span
            ><span class="c1"
              >– Specialiosiose sąlygose nurodyta Kredito davėjo ir (ar) Kredito davėjo tarpininko internetinė svetainė,
              kurioje Kredito gavėjas pateikia Paraišką suteikti Vartojimo kreditą, susipažįsta su Bendrosiomis
              sąlygomis, Standartine informacija ir Privatumo politika</span
            ><span class="c0">;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Privatumo politika </span
            ><span class="c0"
              >– Tinklapyje paskelbta privatumo politika, kurioje nurodomos Kredito gavėjo, jo sutuoktinio tvarkomų
              asmens duomenų sąlygos ir tvarka.</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Paraiška</span><span class="c0">&nbsp;– </span
            ><span class="c1"
              >per Tinklapį Kredito gavėjo elektroniniu būdu pateiktas (užpildytas) prašymas suteikti Vartojimo
              kreditą</span
            ><span class="c0">;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c16">Standartinė informacija </span
            ><span class="c1"
              >– Kredito davėjo pagal teisės aktuose nustatytą formą parengta standartinė informacija apie Vartojimo
              kreditą, kuri Kredito gavėjui iki Sutarties sudarymo yra pateikiama Tinklapio aplinkoje, sudarant Kredito
              gavėjui galimybę išsisaugoti šią informaciją patvarioje laikmenoje, kad Kredito gavėjas galėtų palyginti
              skirtingus &nbsp;pasiūlymus, siekdamas priimti pagrįstą sprendimą dėl Sutarties sudarymo</span
            ><span class="c0">;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Bendra Kredito gavėjo mokama suma </span
            ><span class="c0">– Bendros vartojimo kredito sumos ir Bendros vartojimo kredito kainos suma;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Bendra vartojimo kredito suma (arba Vartojimo kreditas) </span
            ><span class="c0"
              >– kredito (pinigų) suma, kurią Kredito davėjas suteikia Kredito gavėjui naudotis šioje Sutartyje
              nustatyta tvarka ir sąlygomis, ir kuri nurodoma Specialiosiose sąlygose;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Bendra vartojimo kredito kaina </span
            ><span class="c0"
              >– visos išlaidos, įskaitant Palūkanas, kurias Kredito gavėjas turi sumokėti pagal šią Sutartį ir kurios
              yra žinomos Kredito davėjui;
            </span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Bendros vartojimo kredito kainos metinė norma (MN) </span
            ><span class="c0"
              >– Bendra vartojimo kredito kaina, išreikšta metiniu kredito kainos procentiniu dydžiu;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Palūkanos </span
            ><span class="c0"
              >– Specialiosiose sąlygose nurodyto dydžio metinės palūkanos, kurias Kredito gavėjas turi mokėti Kredito
              davėjui už naudojimąsi Bendra vartojimo kredito suma iki visos Vartojimo kredito sumos grąžinimo Kredito
              davėjui dienos;</span
            >
          </li>
          <li class="c6 li-bullet-2">
            <span class="c3">Administravimo mokesčiai</span
            ><span class="c0"
              >&nbsp;– &nbsp;Specialiosiose sąlygose nurodytas Kredito gavėjo mokamas šios Sutarties mėnesinis
              administravimo mokestis ir Sutarties sudarymo mokestis, jei</span
            ><span class="c1">&nbsp;taikomas</span
            ><span class="c0"
              >. Sutarties sudarymo mokesčio mokėjimas atidedamas, jį išdėstant visam Vartojimo kredito terminui, ir
              mokamas Grafike nustatytomis dalimis ir terminais;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Vartojimo kredito terminas </span
            ><span class="c0"
              >– terminas, per kurį Kredito gavėjas privalo sumokėti Kredito davėjui Bendrą Kredito gavėjo mokamą sumą
              pagal šią Sutartį. Vartojimo kredito terminas pasibaigia Grafike ir/ar Specialiosiose sąlygose nustatytą
              paskutinės Įmokos mokėjimo dieną;
            </span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Įmoka </span
            ><span class="c0"
              >– bendra per Specialiosiose sąlygose nurodytą laikotarpį Kredito gavėjo grąžintinos Vartojimo kredito
              dalies ir (arba) mokėtinų Palūkanų suma, ir (arba) Administravimo mokesčių suma, nurodyta Grafike ir</span
            ><span class="c1">&nbsp;(</span><span class="c0">ar) Specialiose sąlygose;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Grafikas</span
            ><span class="c0"
              >&nbsp;–Vartojimo kredito grąžinimo grafikas, pagal kurį nustatytomis mokėjimo dienomis Kredito gavėjas
              privalo mokėti nustatytas Įmokas pagal Sutartį.</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c3">Šalys (Šalis)</span
            ><span class="c0">&nbsp;– Kredito davėjas ir (arba) Kredito gavėjas.</span>
          </li>
        </ol>
      </li>
    </ol>
  </li>
  <li>
    
    <h3 class="title">SUTARTIES OBJEKTAS</h3>
    <ol>
      <li>
        Šia Sutartimi, kuri Šalių yra sudaroma elektroninėmis priemonėmis nuotoliniu būdu, Kredito davėjas įsipareigoja
        suteikti Kredito gavėjui Vartojimo kreditą, o Kredito gavėjas įsipareigoja grąžinti Kredito davėjui Vartojimo
        kreditą bei sumokėti Bendrą vartojimo kredito kainą ir įvykdyti kitas Sutartyje nustatytas prievoles, laikantis
        Sutartyje nustatytos tvarkos ir sąlygų
      </li>
      <li>
        Esmine šios Sutarties sąlyga Kredito davėjas laiko Kredito gavėjo toliau pateiktus patvirtinimus. Kredito
        davėjas laikys šią Sutartį iš esmės pažeista, jei bet kuris iš šių Kredito gavėjo patvirtinimų neatitinka
        tikrovės arba yra melagingas:
        <ol>
          <li class="c15 li-bullet-1">
            <span class="c0">Kredito gavėjo pateikti duomenys bei patvirtinimai yra teisingi, tikslūs ir išsamūs;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">
              Kredito gavėjas patvirtina, kad atidžiai perskaitė Standartinę informaciją, kuri Kredito gavėjui buvo
              pateikta
            </span>
            <span class="c1">Tinklapio aplinkoje, sudarant galimybę ją išsisauogti patvarioje</span>
            <span class="c0">&nbsp;laikmenoje, iki šios Sutarties sudarymo momento;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c1">
              Kredito gavėjas, pateikdamas Paraišką ir (ar) sudarydamas Sutartį, atidžiai perskaitė Tinklapyje
              paskelbtas tinklapio naudojimo taisykles iki šios Sutarties sudarymo momento
            </span>
            <span class="c0">;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">
              Kredito gavėjas susipažino, sutinka ir leidžia Kredito davėjui, įskaitant tai pačiai įmonių grupei
              priklausančioms bendrovėms, Kredito davėjo Privatumo politikoje nustatyta tvarka ir sąlygomis tvarkyti jo
              asmens duomenis;
            </span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">
              Kredito gavėjas, jeigu susituokęs, patvirtina, kad jo sutuoktinis laisva valia sutiko ir leido Kredito
              davėjui, įskaitant tai pačiai įmonių grupei priklausančioms bendrovėms, Privatumo politikoje nustatyta
              tvarka ir sąlygomis tvarkyti jo asmens duomenis bei tikrinti jo finansinę padėtį registruose ir
              informacinėse sistemose, kurios naudojamos kreditingumo vertinimui; &nbsp;
            </span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c1">
              Kredito gavėjas atidžiai perskaitė Sutarties sąlygas, jas supranta, o atlikdamas šioje Sutartyje nurodytus
              veiksmus, kuriais yra autentifikuojama Kredito gavėjo tapatybė ir patvirtinama Kredito gavėjo valia
              sudaryti Sutartį, aiškiai pareiškia, jog su Sutarties sąlygomis sutinka laisva valia, supranta savo
              pareigas bei įsipareigoja jas vykdyti
            </span>
            <span class="c0">;</span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">
              Kredito gavėjas turėjo galimybę konsultuotis su nepriklausomais teisės ir finansų patarėjais dėl Sutarties
              sudarymo joje numatytomis sąlygomis, o sprendimas sudaryti Sutartį yra priimtas apgalvotai ir atsakingai;
            </span>
          </li>
          <li class="c6 li-bullet-2">
            <span class="c0">
              Kredito gavėjas yra pajėgus prisiimti konkretų, šioje Sutartyje nustatytą, finansinį įsipareigojimą, kurį
              kartu su jau turimais finansiniais įsipareigojimais Kredito gavėjas yra pajėgus įvykdyti;
            </span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">
              Kredito gavėjas yra mokus ir nėra jokių kitų Paraiškoje nenurodytų aplinkybių, kurios galėtų neigiamai
              paveikti jo kreditingumą;
            </span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">
              Kredito gavėjas šios Sutarties sudarymo metu yra Lietuvos Respublikoje nuolatos gyvenantis, veiksnus ir ne
              jaunesnis kaip 18 metų fizinis asmuo
            </span>
            <span class="c1">
              , kuris Sutarties sudarymo metu nėra apsvaigęs nuo alkoholio, narkotikų ar psichotropinių medžiagų;
            </span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c1">
              Tuo atveju, kai Kredito gavėjas Paraiškoje nurodo, jog Vartojimo kredito paskirtis yra šeimos poreikių
              tenkinimui, Kredito gavėjas patvirtina, kad Sutartis yra sudaroma Kredito gavėjo sutuoktiniui žinant ir
              sutinkant, Sutartis neprieštarauja Kredito gavėjo šeimos interesams ir yra susijusi su šeimos poreikių
              tenkinimu;
            </span>
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">
              Kredito gavėjo Sutartimi prisiimti įsipareigojimai nepažeidžia jokių trečiųjų asmenų teisių ar teisėtų
              interesų, neprieštarauja jokioms teisės akto nuostatoms, sandorio, kurio šalimi yra Kredito gavėjas,
              sąlygoms, jokiam teismo, arbitražo ar kito kompetentingo organo sprendimui, kuris taikytinas Kredito
              gavėjui, o ši Sutartis yra sudaryta gavus sutuoktinio ar kitus teisės aktų reikalaujamus sutikimus ar
              leidimus.
            </span>
          </li>
        </ol>
      </li>
    </ol>
  </li>
  <li>
  <h3 class="title">VARTOJIMO KREDITO SUTEIKIMO SĄLYGOS IR TVARKA</h3>
    
    <ol>
      <li>
        Teisę kreiptis dėl Vartojimo kredito suteikimo turi Lietuvos Respublikoje nuolatos gyvenantis, veiksnus ir ne
        jaunesnis kaip 18 metų fizinis asmuo.
      </li>
      <li>
        Kredito gavėjas, norėdamas gauti Vartojimo kreditą, privalo:
        <ol>
          <li class="c6 li-bullet-1">
            <span class="c0"
              >užpildyti Tinklapyje Kredito davėjo pateikiamą Paraiškos formą, laikantis Tinklapyje nurodomų jos pildymo
              instrukcijų;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">pateikiant Paraišką, </span
            ><span class="c1"
              >patvirtinti, kad atidžiai susipažino su Privatumo politika. Pranešimas dėl asmens duomenų tvarkymo taip
              pat išsiunčiamas Kredito gavėjo sutuoktiniui patvarioje laikmenoje Paraiškoje nurodytu jo sutuoktinio el.
              paštu (kai kreipiamasi dėl Vartojimo kredito šeimos poreikiams tenkinti);</span
            >
          </li>
          <li class="c6 li-bullet-2">
            <span class="c1"
              >patvirtinti Kredito davėjo pateiktą pasiūlymą sudaryti Sutartį ir Tinklapio aplinkoje iki Sutarties
              sudarymo susipažinti su Kredito davėjo pateikta Standartine informacija, kurią Kredito gavėjas gali
              išsisaugoti patvarioje laikmenoje;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c1"
              >pasirašyti Sutartį, Tinklapio aplinkoje atliekant Sutarties sudarymo (patvirtinimo) veiksmus, kuriais
              patvirtinama Kredito gavėjo valia sudaryti Sutartį;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c1">sudarant Sutartį, </span><span class="c0">atlikti </span
            ><span class="c1"
              >Kredito gavėjo identifikavimo (tapatybės nustatymo) veiksmus vienu iš Tinklapyje nurodytu ir leidžiamu
              nuotoliniu autentifikavimo būdu;</span
            >
          </li>
          <li class="c6 li-bullet-1">
            <span class="c0">patvirtinti, kad atidžiai perskaitė Standartinę informaciją iki </span
            ><span class="c1">Sutarties sudarymo</span><span class="c0">&nbsp;ir ją suprato;</span>
          </li>
        </ol>
      </li>
      <li class="c2 li-bullet-1">
        <span class="c0"
          >Kredito gavėjas, norėdamas pateikti Paraišką, turi užpildyti visus Paraiškoje nurodytus reikalaujamus
          duomenis ir (ar) informaciją, kurie turi būti tikslūs ir teisingi, bei atlikti visus kitus Bendrųjų sąlygų
          3.2.</span
        ><span class="c1">2</span
        ><span class="c0">&nbsp;punkte nurodomus veiksmus, kuriuos atlikus Paraiška laikoma tinkamai pateikta.</span>
      </li>
      <li class="c2 li-bullet-1">
        <span class="c0"
          >Bendra Vartojimo kredito suma, Bendra Vartojimo kredito kaina, Palūkanų norma, Administravimo mokesčiai,
          Vartojimo kredito terminas ir kitos sąlygos yra nurodomos Specialiosiose sąlygose pagal Kredito gavėjo
          Paraiškoje nurodytus pageidavimus ir atsižvelgiant į Kredito gavėjo kreditingumo vertinimo rezultatus.</span
        >
      </li>
      <li class="c2 li-bullet-2">
        <span class="c0"
          >Kredito gavėjo Sutartyje nustatyta tvarka tinkamai pateikta Paraiška yra vertinama Kredito davėjo
          vadovaujantis Kredito davėjo patvirtintomis Kredito gavėjų kreditingumo vertinimo ir atsakingojo skolinimo
          taisyklėmis.</span
        >
      </li>
      <li class="c2 li-bullet-1">
        <span class="c0"
          >Kredito davėjas priima sprendimą suteikti Vartojimo kreditą, kai teigiamai yra įvertinamas Kredito gavėjo
          kreditingumas ir nustatoma, kad Kredito gavėjas yra pajėgus vykdyti šia Sutartimi prisiimamus įsipareigojimus
          kartu su kitais turimais finansiniais įsipareigojimais.</span
        >
      </li>
      <li class="c2 li-bullet-1">
        <span class="c0"
          >Kredito davėjas apie priimta sprendimą suteikti (nesuteikti) Kredito gavėjo Paraiškoje nurodytą Vartojimo
          kreditą informuoja Kredito gavėją jo Paraiškoje nurodytu el. paštu</span
        ><span class="c1">&nbsp;ir kartu Kredito gavėjui pateikia pasiūlymą sudaryti Sutartį</span
        ><span class="c0"
          >. Jeigu Kredito gavėjas priima sprendimą nesuteikti Vartojimo kredito, Kredito gavėjas yra informuojamas apie
          jo kreditingumo vertinimo rezultatus ir jam pateikiama informacija apie registrus ir informacines sistemas,
          kuriose atliktas jo kreditingumo patikrinimas.</span
        >
      </li>
      <li class="c2 li-bullet-1">
        <span class="c0"
          >Tuo atveju, kai Kredito gavėjo kreditingumo vertinimo rezultatų pagrindu Kredito gavėjui gali būti suteikiama
          mažesnė nei Paraiškoje nurodoma jo pageidaujama Vartojimo kredito suma, apie tai yra informuojamas Kredito
          gavėjas, kuris priima sprendimą (sutinka / atsisako) sudaryti Sutartį dėl mažesnės Vartojimo kredito sumos
          suteikimo.</span
        >
      </li>
      <li class="c2 li-bullet-1">
        <span class="c1"
          >Sutartis yra sudaroma elektroninėmis priemonėmis nuotoliniu būdu, Šalims pasirašant Specialiąsias sąlygas.
          Kredito gavėjas Specialiąsias sąlygas pasirašo naudojant kvalifikuotą elektroninį parašą Mobile-ID (ipasas.lt)
          arba Smart-ID, arba kitu Kredito davėjo siūlomu ir (ar) Tinklapyje nurodytu elektroniniu būdu, kuriuo yra
          autentifikuojama Kredito gavėjo tapatybė ir patvirtinama Kredito gavėjo valia sudaryti Sutartį. Kredito gavėjo
          pasirašytos Specialiosios sąlygos išsiunčiamos Kredito gavėjui patvarioje laikmenoje jo Paraiškoje ir
          Specialiosiose sąlygose nurodytu el. paštu</span
        ><span class="c0">. </span>
      </li>
      <li class="c2 li-bullet-1">
        <span class="c0">Vartojimo kreditas išmokamas Kredito gavėjui </span><span class="c1">sekančią</span
        ><span class="c0">&nbsp;darbo dien</span><span class="c1">ą po Sutarties sudarymo</span
        ><span class="c0"
          >&nbsp;į Specialiosiose sąlygos nurodytą Kredito gavėjo banko sąskaitą, iš kurios buvo sumokėtas registracijos
          mokestis.</span
        >
      </li>
      <li class="c13 li-bullet-1">
        <span class="c0"
          >Komisinius ir kitokio pobūdžio mokesčius už Vartojimo kredito sumos įskaitymą į Kredito gavėjo banko sąskaitą
          (jei tokių būtų) sumoka Kredito gavėjas.</span
        >
      </li>
    </ol>
  </li>
  <li>
  <h3 class="title">KREDITO GAVĖJO TEISĖ ATSISAKYTI SUTARTIES IR ĮSIPAREIGOJIMŲ PAGAL SUTARTĮ VYKDYMO ATIDĖJIMAS</h3>
    
    <ol>
      <li class="c13 li-bullet-1">
        Kredito gavėjas, nenurodydamas atsisakymo priežasčių, turi teisę atsisakyti šios Sutarties per 2 (dviejų)
        kalendorinių dienų apsisprendimo laikotarpį, kuris skaičiuojamas nuo Vartojimo kredito išmokėjimo Kredito
        gavėjui dienos. Naudodamasis šia teise, Kredito gavėjas ne vėliau kaip iki apsisprendimo laikotarpio pabaigos
        privalo:
        <ol>
          <li class="c13 li-bullet-1">
            pateikti Kredito davėjui Bendrųjų sąlygų 10.5 punkte nurodyta tvarka pasirašytą pranešimą, kuriame nurodo
            savo vardą, pavardę, asmens kodą ir Sutarties numerį, bei pateikia pareiškimą, kad Kredito gavėjas
            pageidauja atsisakyti Sutarties, ir
          </li>
          <li class="c13 li-bullet-1">
            grąžinti Kredito davėjui visą jam išmokėtą Vartojimo kredito sumą į Specialiosiose sąlygose nurodytą Kredito
            davėjo banko sąskaitą, nemokėdamas Palūkanų ir Administravimo mokesčių, kitų išlaidų ar kompensacijų.
          </li>
        </ol>
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas, nenurodydamas atsisakymo priežasčių, turi teisę atsisakyti šios Sutarties per 14 (keturiolika)
        kalendorinių dienų nuo Vartojimo kredito išmokėjimo Kredito gavėjui dienos arba nuo dienos, kurią Kredito
        gavėjas gavo Sutarties nuostatas ir sąlygas (jei ji yra vėlesnė už Sutarties sudarymo dieną). Naudodamasis šia
        teise, Kredito gavėjas ne vėliau kaip iki nustatyto Sutarties atsisakymo termino pabaigos privalo:
        <ol>
          <li class="c13 li-bullet-1">
            pateikti Kredito davėjui Bendrųjų sąlygų 10.5 punkte nurodyta tvarka pasirašytą pranešimą, kuriame nurodo
            savo vardą, pavardę, asmens kodą ir Sutarties numerį, bei pateikia pareiškimą, kad Kredito gavėjas
            pageidauja atsisakyti Sutarties, ir
          </li>
          <li class="c13 li-bullet-1">
            nedelsiant, bet ne vėliau kaip per 30 (trisdešimt) kalendorinių dienų nuo pranešimo apie Sutarties
            atsisakymą išsiuntimo Kredito davėjui dienos, grąžinti Kredito davėjui visą jam išmokėtą Vartojimo kredito
            sumą bei sumokėti Palūkanas, susikaupusias nuo dienos, kurią Kredito gavėjui buvo išmokėtas Vartojimo
            kreditas, iki Vartojimo kredito grąžinimo dienos. Palūkanos yra apskaičiuojamos pagal Specialiosiose
            sąlygose nurodytą Palūkanų normą.
          </li>
        </ol>
      </li>
      <li class="c13 li-bullet-1">
        Jeigu Sutartyje pateikta ne visa Lietuvos Respublikos teisės aktų nustatyta privaloma pateikti informacija arba
        jeigu Sutartyje nurodyta informacija yra klaidinanti ir tai turėjo esminės įtakos Kredito gavėjo sprendimui
        sudaryti šią Sutartį, kurios jis kitomis aplinkybėmis nebūtų sudaręs, Kredito gavėjas turi teisę atsisakyti
        Sutarties, pranešdamas Kredito davėjui apie Sutarties atsisakymą prieš 30 kalendorinių dienų, pateikiant
        rašytinį pranešimą Specialiosiose sąlygose nurodytu Kredito davėjo el. paštu. Šiuo atveju Kredito gavėjas turi
        Vartojimo kreditą grąžinti mokėdamas Sutartyje nustatyto dydžio įmokas, tačiau jis neprivalo mokėti Sutartyje
        nustatytų Palūkanų ir kitų išlaidų.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas turi teisę atidėti Vartojimo kredito įmokų, išskyrus Palūkanas, mokėjimą ne ilgesniam kaip 3
        mėnesių laikotarpiui, pateikdamas rašytinį prašymą Specialiose sąlygose nurodytu Kredito davėjo el. paštu, kai
        Kredito gavėjas nebetenkina kreditingumo vertinimo ir atsakingojo skolinimo reikalavimų, ir yra bent viena iš
        šių aplinkybių:
        <ol>
          <li class="c13 li-bullet-1">nutrūksta Kredito gavėjo santuoka;</li>
          <li class="c13 li-bullet-1">miršta Kredito gavėjo sutuoktinis;</li>
          <li class="c13 li-bullet-1">
            Kredito gavėjas ar jo sutuoktinis tampa bedarbiu arba netenka ne mažiau kaip trečdalio pajamų;
          </li>
          <li class="c13 li-bullet-1">
            Kredito gavėjas pripažįstamas nedarbingu arba iš dalies darbingu Lietuvos Respublikos neįgaliųjų socialinės
            integracijos įstatyme nustatyta tvarka;
          </li>
          <li class="c13 li-bullet-1">Kredito gavėjas atlieka nuolatinę privalomąją pradinę karo tarnybą.</li>
        </ol>
      </li>
      <li class="c13 li-bullet-1">
        Atidėjus Įmokų mokėjimą, bendru Šalių sutarimu perskaičiuojamos atidėtos Įmokos ir sudaromas atnaujintas
        Grafikas, kuris išsiunčiamas patvarioje laikmenoje Specialiosiose sąlygose nurodytu Kredito gavėjo el. paštu.
      </li>
      <li class="c13 li-bullet-1">
        Įsipareigojimų pagal Kredito sutartį vykdymo, Įmokų mokėjimo, atidėjimas yra negalimas tais atvejais, kai
        Kredito gavėjo prašymo pateikimo dieną Kredito gavėjas atitinka bent vieną iš žemiau nurodytų kriterijų:
        <ol>
          <li class="c6 li-bullet-1">vėluoja mokėti bent vieną Įmoką;</li>
          <li class="c6 li-bullet-1">vyksta ikiteisminis skolos išieškojimas;</li>
          <li class="c6 li-bullet-1">vyksta teisminis skolos išieškojimas.</li>
        </ol>
      </li>
    </ol>
  </li>
  <li>
    
    <h3 class="title">VARTOJIMO KREDITO GRĄŽINIMO SĄLYGOS IR TVARKA</h3>
    <ol>
      <li class="c13 li-bullet-1">
        Kredito gavėjas grąžina Vartojimo kredito sumą bei sumoka Palūkanas ir Administravimo mokesčius griežtai
        laikydamasis Specialiosiose sąlygose nustatyto Grafiko, pagal Specialiosiose sąlygose, ar Kredito davėjo
        sąskaitoje pateiktus nurodymus. Jei šiomis priemonėmis pateikti nurodymai skiriasi, viršenybė teikiama
        sąskaitoje pateiktiems nurodymams. Kredito gavėjas turi teisę bet kada šios Sutarties galiojimo laikotarpiu
        nemokamai gauti Įmokų mokėjimo grafiką.
      </li>
      <li class="c13 li-bullet-1">
        Sąskaita išrašoma ir pateikiama Specialiosiose sąlygos nurodytu Kredito gavėjo el. paštu ne vėliau kaip iki
        kiekvienos Grafike nurodytos Įmokos mokėjimo termino pabaigos.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas mokėjimo pavedimo paskirtyje privalo aiškiai nurodyti savo asmens kodą ir Sutarties numerį.
        Nenurodžius arba neaiškiai nurodžius mokėjimo paskirtį, Kredito gavėjas mokėjimą įskaito atsižvelgdamas į mokamą
        sumą, mokėjimo laiką ir kitas aplinkybes, pagal kurias galima nustatyti Kredito gavėjo valią.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas turi teisę anksčiau numatyto termino įvykdyti visus arba dalį savo įsipareigojimų pagal Sutartį,
        pateikdamas ne vėliau kaip prieš 5 (penkias) darbo dienas raštišką pranešimą Specialiosiose sąlygos nurodytu
        Kredito davėjo el. paštu, ir grąžindamas visą (arba dalį) jam išmokėtos Vartojimo kredito sumos, bei sumokėdamas
        iki Vartojimo kredito grąžinimo dienos priskaičiuotas Palūkanas ir Administravimo mokesčius. Kredito gavėjas
        pranešime turi nurodyti šios Sutarties numerį, grąžinamą Vartojimo kredito sumą ir datą, kurią pageidauja
        grąžinti Vartojimo kredito sumą. Jeigu anksčiau nustatyto termino grąžinama tik dalis Vartojimo kredito sumos,
        pranešime Kredito gavėjas turi nurodyti, ar jo pageidavimu turėtų būti trumpinamas Vartojimo kredito grąžinimo
        terminas, Įmokos dydį paliekant nepakeistą, ar turi būti mažinamas Įmokos dydis nekeičiant galutinio Vartojimo
        kredito grąžinimo termino.
      </li>
      <li class="c13 li-bullet-1">
        Vartojimo kredito gavėjas, grąžindamas Vartojimo kreditą (jo dalį) anksčiau numatyto termino, turi teisę į
        Bendros vartojimo kredito kainos sumažinimą, kurį sudaro likusio Sutarties trukmės laikotarpio, skaičiuojamo nuo
        Vartojimo kredito ar jo dalies grąžinimo dienos, Palūkanos ir Administravimo mokesčiai. Vartojimo kredito (jo
        dalies) grąžinimo anksčiau nustatyto termino atveju Bendros vartojimo kredito kainos sumažinimas turėtų apimti
        proporcingą palūkanų ir kitų išlaidų sumažinimą ir įvertinimą, kad Administravimo mokesčių ir visų kitų išlaidų
        suma, apskaičiuota vienai naudojimosi suteiktu Vartojimo kreditu dienai, neturėtų būti didesnės kaip 0,04%
        Bendros vartojimo kredito sumos.
      </li>
      <li class="c13 li-bullet-1">
        Kredito davėjas pareiškia, kad už Vartojimo kredito grąžinimą aukščiau nustatyto termino iš Kredito gavėjo nebus
        reikalaujama sumokėti kompensaciją.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjo mokėjimo prievolė yra laikoma įvykdyta tuo momentu, kai mokama suma yra įskaitoma į Kredito
        davėjo Specialiosiose sąlygose nurodytą banko sąskaitą. Jeigu Grafike nurodoma mokėjimo diena sutampa su ne
        darbo ar oficialios šventės diena, mokėjimo diena laikoma pirmoji po jos einanti darbo diena.
      </li>
      <li class="c13 li-bullet-1">
        Visi kredito gavėjo mokėjimai yra atliekami eurais ir užskaitomi tokiu eiliškumu:
        <ol>
          <li class="c13 li-bullet-1">
            pradelstų mokėjimų išieškojimo išlaidos (būtinosios vykdymo išlaidos, žyminis mokestis, kitos notarų ar
            antstolių rinkliavos, patirtos bylinėjimosi išlaidos);
          </li>
          <li class="c13 li-bullet-1">mokėtinos netesybos (delspinigiai);</li>
          <li class="c13 li-bullet-1">Palūkanos ir Administravimo mokesčiai;</li>
          <li class="c13 li-bullet-1">grąžinama Vartojimo kredito suma.</li>
        </ol>
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjui sumokėjus didesnę pinigų sumą negu privaloma mokėti pagal Sutartį, ši permoka įskaitoma į kitus
        Kredito gavėjo mokėjimus pagal Sutartį arba kitas Šalių sudarytas Sutartis, arba esant Kredito gavėjo prašymu
        yra grąžinama į Kredito gavėjo nurodytą sąskaitą, arba panaudojamos kitu, Kredito gavėjo nurodytu, būdu. Kredito
        gavėjui suteikus naują vartojimo kreditą, permokos suma Kredito gavėjo rašytiniu pareiškimu, pateikiamu
        Specialiose sąlygose nurodytu Kredito gavėjo el. paštu, arba Kredito davėjo sprendimu yra įskaitomos į vėlesnes
        Kredito gavėjo mokėtinas sumas. Pranešimas apie įskaitymą yra pateikiamas atskirai arba pažymint apie įskaitymą
        Kredito gavėjui sąskaitoje, pateikiamoje šių Bendrųjų sąlygų 5.2. punkte nustatyta tvarka.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjo permokos suma iki Kredito gavėjo rašytinio prašymo gavimo ar įskaitymo pagal šių Bendrųjų sąlygų
        5.9. punktą dienos yra saugoma Kredito davėjo banko sąskaitoje ir už šį laikotarpį Kredito gavėjas neturi teisės
        reikalauti Kredito davėjo mokėti palūkanas.
      </li>
      <li class="c13 li-bullet-1">
        Kredito davėjo išlaidos, patiriamos dėl bankų taikomų mokesčių vykdant Kredito gavėjo nurodymus dėl permokų
        grąžinimo, yra apmokamos išskaitant jas iš permokos sumos. Tuo atveju, jei permokos suma yra mažesnė nei
        atitinkamo banko taikomi mokesčiai, Kredito davėjas turi teisę pareikalauti iš anksto apmokėti šias išlaidas.
      </li>
    </ol>
  </li>
  <li>
    
  <h3 class="title">PALŪKANŲ, BENDROS VARTOJIMO KREDITO KAINOS IR JOS METINĖS NORMOS (MN) APSKAIČIAVIMO BEI TAIKYMO SĄLYGOS IR TVARKA</h3>
    
    <ol>
      <li class="c13 li-bullet-1">
        Kredito gavėjas už naudojimąsi Vartojimo kreditu privalo mokėti Kredito davėjui Specialiose sąlygose nustatyto
        dydžio metines Palūkanas, kurios apskaičiuojamos nuo bendros išduodamo Vartojimo kredito sumos ir proporcingai
        paskirstomos Grafike nurodytoms Įmokoms. Konkretų mėnesį mokėtinų Palūkanų suma yra nurodoma Grafike.
      </li>
      <li class="c13 li-bullet-1">
        Palūkanos pradedamos skaičiuoti nuo Bendros vartojimo kredito sumos išmokėjimo Kredito gavėjui dienos iki
        dienos, kada visa ši suma yra faktiškai grąžinama Kredito davėjui, išskyrus šiose Bendrosiose sąlygose nurodytus
        atvejus, kuomet Palūkanos nėra mokamos.
      </li>
      <li class="c13 li-bullet-1">
        Bendra vartojimo kredito kainos metinė norma (MN) yra nurodoma Specialiose sąlygose, kuri apskaičiuojama
        vadovaujantis Lietuvos banko 2012 m. lapkričio 29 d. nutarime Nr. 03-245 „Dėl Bendros vartojimo kredito kainos
        metinės normos skaičiavimo taisyklių patvirtinimo“ (su visais pakeitimais ir papildymais) nustatyta tvarka,
        remiantis šiomis prielaidomis:
        <ol>
          <li class="c13 li-bullet-1">
            Šalys vykdys savo įsipareigojimus pagal Sutartyje nustatytas sąlygas ir terminus;
          </li>
          <li class="c13 li-bullet-1">
            Palūkanų norma bei kiti su Sutarties sudarymu bei jo vykdymu susiję mokesčiai išliks tokie kaip Sutarties
            sudarymo metu iki Sutarties pabaigos;
          </li>
          <li class="c13 li-bullet-1">
            Vartojimo kreditas grąžinamas laikantis Sutarties Specialiosiose sąlygose numatyto Įmokų mokėjimo grafiko ir
            kitų sutartų sąlygų;
          </li>
        </ol>
      </li>
      <li class="c13 li-bullet-1">
        Kredito davėjo nuostoliai, patirti dėl Kredito gavėjo sutarties pažeidimo (jei toks būtų), t.y. negautos pajamos
        ir/ar išlaidos, kurias jis patiria siekdamas apginti, išsaugoti ir/ar įgyvendinti savo pažeistas teises pagal
        Sutartį (įskaitant, bet neapsiribojant priminimo žinučių ir laiškų kredito gavėjui siuntimo išlaidos, išlaidos
        trečiųjų asmenų paslaugoms, bylinėjimosi ir išieškojimo išlaidos), kurios nėra žinomos iš anksto, nėra
        įskaičiuojamos į Bendrą vartojimo kredito kainą ir, jei Šalys patiria šias išlaidas, šios išlaidos turi būti
        atlyginamos Lietuvos Respublikos teisės aktų nustatyta tvarka.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjo išlaidos, kurias jis patiria dėl mokesčių, taikomų Kredito gavėjo mokėjimus vykdančių įstaigų,
        priimančių ir/ar vykdančių atitinkamus kredito gavėjo pagal šią Sutartį atliekamus mokėjimus, nėra
        įskaičiuojamos į Bendrą vartojimo kredito kainą.
      </li>
      <li class="c13 li-bullet-1">
        Bendra vartojimo kredito gavėjo mokama suma yra apskaičiuojama sudedant Bendrą vartojimo kredito kainą su Bendra
        vartojimo kredito suma, nurodyta Specialiose sąlygose.
      </li>
      <li class="c13 li-bullet-1">
        Bendra vartojimo kredito kainos metinė norma ir Bendra vartojimo kredito gavėjo mokama suma yra apskaičiuojamos
        nuo Specialiose sąlygose nurodytų duomenų apie Vartojimo kredito sumą ir Vartojimo kredito terminą.
      </li>
    </ol>
  </li>
  <li>
    
    <h3 class="title">KREDITORIAUS TEISĖ NUTRAUKTI SUTARTĮ IR ATSAKOMYBĖS SĄLYGOS</h3>
    <ol>
      <li class="c13 li-bullet-1">
        Kredito davėjas turi teisę vienašališkai nutraukti sutartį ir pareikalauti sumokėti visą Vartojimo kredito sumą,
        Administravimo mokesčius, Palūkanas ir kitas pagal šios Sutarties bei Lietuvos Respublikos teisės aktų nuostatas
        mokėtinas sumas, kai yra visos šios sąlygos:
        <ol>
          <li class="c13 li-bullet-1">
            Kredito gavėjas pradelsia bet kurį mokėjimą ilgiau kaip 1 (vieną) mėnesį ir jo suma yra ne mažesnė kaip 10
            procentų negrąžintos Vartojimo kredito sumos arba mokėjimas yra pradelstas ilgiau kaip tris mėnesius iš
            eilės; ir
          </li>
          <li class="c13 li-bullet-1">
            Kredito davėjas rašytiniu pranešimu informuoja Kredito gavėją apie pradelstą mokėjimą, pateikiant šį
            pranešimą patvarioje laikmenoje Specialiosiose sąlygose nurodytu Kredito gavėjo el. paštu arba gyvenamosios
            vietos adresu; ir
          </li>
          <li class="c13 li-bullet-1">
            Kredito gavėjas pilnai nepadengia pradelsto mokėjimo per 2 (dvi) savaites nuo šiame punkte nurodyto
            rašytinio pranešimo įteikimo Kredito gavėjui dienos. Pranešimas laikomas įteiktu praėjus 5 (penkioms)
            kalendorinėms dienoms po jo išsiuntimo.
          </li>
        </ol>
      </li>
      <li class="c13 li-bullet-1">
        Nutraukus šią sutartį, visi šioje Sutartyje nurodyti mokėjimo terminai yra laikomi suėjusiais ir Kredito gavėjas
        privalo sutarties nutraukimo dieną grąžinti visą negrąžintą Vartojimo kredito sumą, sumokėti visus
        Administravimo mokesčius ir Palūkanas, apskaičiuotas iki Sutarties nutraukimo dienos. Sutarties nutraukimas
        nesustabdo Kredito gavėjo prievolių pagal Sutartį vykdymo iki kol bus grąžintas visas Vartojimo kreditas,
        sumokėtos Palūkanos, Administravimo mokestis ir netesybos, bei visos kitos pagal Sutartį Kredito gavėjo
        mokėtinos sumos
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjui praleidus Vartojimo kredito, Administravimo mokesčio ir/ar Palūkanų mokėjimo terminą nutraukus
        šią Sutartį, Kredito gavėjas įsipareigoja mokėti 0,05% delspinigius nuo visos pradelstos sumokėti sumos už
        kiekvieną pradelstą dieną ir Lietuvos Respublikos teisės aktuose nustatyta tvarka atlyginti Kredito davėjo
        tiesioginius ir netiesioginius nuostolius, viršijančius šią delspinigių sumą. Šalių sutarimu:
        <ol>
          <li class="c13 li-bullet-1">
            Tiesioginiais nuostoliais yra pripažįstamos visos išlaidos, kurias Kredito davėjas patiria siekdamas
            apginti, išsaugoti ir/ar įgyvendinti savo pažeistas teises pagal nutrauktą Sutartį, įskaitant (bet
            neapsiribojant): priminimo žinučių ir laiškų Kredito gavėjui siuntimo išlaidas, išlaidos trečiųjų asmenų
            paslaugoms, žyminio mokesčio, bylinėjimosi ir išieškojimo išlaidos;
          </li>
          <li>
            Netiesioginiais Kredito davėjo nuostoliais yra laikomos jo negautos pajamos, tai yra – Administravimo
            mokesčiai ir Palūkanos, kurias Kredito davėjas būtų gavęs, jei Kredito gavėjas būtų tinkamai vykdęs šią
            Sutartį.
          </li>
        </ol>
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjui praleidus Įmokų mokėjimo terminą iki Sutarties nutraukimo, šioje Sutartyje nustatytos Palūkanos
        yra skaičiuojamos toliau nuo visos pradelstos sumokėti Vartojimo kredito sumos už visą pradelsimo laiką ir
        Kredito gavėjas privalo mokėti 0,05% delspinigių nuo visos pradelstos sumokėti sumos už kiekvieną pradelstą
        dieną. Jokios kitos netesybos ir mokesčiai už Sutartyje numatytų finansinių įsipareigojimų nevykdymą Kredito
        gavėjui nėra taikomi. Delspinigiai nuo visos pradelstos sumokėti sumos yra skaičiuojami ne ilgiau kaip už bendrą
        180 dienų laikotarpį.
      </li>
      <li class="c13 li-bullet-1">
        Kredito davėjas nėra laikomas atsakingu už bet kokių įsipareigojimų pagal šią Sutartį neįvykdymą ar dalinį
        neįvykdymą bei neatsako už nuostolius, jeigu tai įvyko dėl nenugalimos jėgos aplinkybių, įskaitant dėl trečiųjų
        asmenų veiksmų, ryšio perdavimo, elektroninių duomenų mainų ir mokėjimo sistemų, tame tarpe ir internetinės
        bankininkystės sutrikimų, kurie įvyko ne dėl Kredito davėjo kaltės, o tretieji asmenys, dėl kurių veiksmų įvyko
        Sutarties pažeidimas, yra nesusiję su Kredito davėju, ir Kredito davėjas negalėjo jų veiksmų numatyti ar jų
        kontroliuoti.
      </li>
      <li class="c13 li-bullet-1">
        Kredito davėjas turi teisę vienašališkai sustabdyti savo įsipareigojimų pagal šią Sutartį vykdymą, jei Kredito
        davėjas pagrįstai daro išvadą, kad Kredito gavėjas galimai dalyvauja pinigų plovimo ir teroristų finansavimo
        veikose. Sutarties vykdymas yra atnaujinamas Kredito gavėjui ištaisius visus Sutarties vykdymo trūkumus ar
        pašalinus Kredito davėjui kilusias abejones.
      </li>
    </ol>
  </li>
  <li>
    
    <h3 class="title">GINČŲ SPRENDIMŲ SĄLYGOS IR TVARKA</h3>
    <ol>
      <li class="c13 li-bullet-1">
        Kredito gavėjo ginčai su Kredito davėju yra nagrinėjami Lietuvos Respublikos Lietuvos banko įstatyme ir Kredito
        davėjo patvirtintose Kredito gavėjų skundų nagrinėjimo taisyklėse nustatyta tvarka.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas, manantis, kad Kredito davėjas pažeidė jo teises ar teisėtus interesus, atsirandančius iš
        Sutarties ar su jais susijusių santykių, turi teisę kreiptis į teismą arba į vartojimo ginčus ne teismo tvarka
        nagrinėjančią instituciją – Lietuvos banką. Kredito gavėjo kreipimasis į Lietuvos banką neatima Kredito gavėjo
        teisės kreiptis į teismą.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas, manantis, kad Kredito davėjas pažeidė jo teises ar teisėtus interesus, atsirandančius iš
        Sutarties ar su jais susijusių santykių, ir ketinantis dėl kilusio ginčo nagrinėjimo kreiptis į Lietuvos banką,
        prieš kreipdamasis į Lietuvos banką privalo raštu kreiptis į Kredito davėją, nurodydamas ginčo aplinkybes ir
        savo reikalavimą. Kredito davėjas šį kreipimąsi nagrinėja neatlygintinai. Rašytinis pareiškimas yra teikiamas
        Bendrųjų sąlygų 10.5 punkte nustatyta tvarka. Kredito davėjas privalo išnagrinėti Kredito gavėjo kreipimąsi ir
        pateikti Kredito gavėjui motyvuotą atsakymą dėl jo reikalavimo ne vėliau kaip per 15 (penkiolika) darbo dienų
        nuo Kredito gavėjo kreipimosi gavimo dienos.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas, kurio Kredito davėjo atsakymas netenkina, turi teisę dėl to paties ginčo dalyko kreiptis į
        Lietuvos banką per vienerius metus nuo jį netenkinančio Kredito davėjo atsakymo gavimo dienos. Jeigu Kredito
        davėjas per šių Bendrųjų sąlygų 8.3. punkte nurodytą terminą Kredito gavėjui neatsako, Kredito gavėjas turi
        teisę kreiptis į Lietuvos banką (adresas: Totorių g. 4, 01121 Vilnius, Lietuva; tel.: +370 800 50 500, +370 5
        251 2763; el. paštas: prieziura@lb.lt) dėl ginčo nagrinėjimo per vienerius metus nuo šio termino pabaigos.
        Skundai Lietuvos bankui gali būti teikiami elektroniniu būdu, per elektroninę ginčų nagrinėjimo priemonę
        „Elektroniniai valdžios vartai“ arba el. paštu prieziura@lb.lt. Daugiau informacijos apie vartotojų ginčų
        nagrinėjimą galite rasti Lietuvos banko internetinėje svetainėje:
        https://www.lb.lt/lt/spreskite-ginca-su-finansiniu-paslaugu-teikeju.
      </li>
    </ol>
  </li>
  <li>
    
    <h3 class="title">ASMENS DUOMENŲ TVARKYMAS</h3>
    <ol>
      <li class="c13 li-bullet-1">
        Tvarkydamas asmens duomenis Kredito davėjas vadovaujasi 2016 m. balandžio 27 d. Europos Parlamento ir Tarybos
        reglamentu (ES) 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir dėl laisvo tokių duomenų
        judėjimo ir kuriuo panaikinama Direktyva 95/46/EB, Lietuvos Respublikos asmens duomenų teisinės apsaugos
        įstatymu, taip pat kituose teisės aktuose, reglamentuojančiuose asmens duomenų tvarkymą ir apsaugą, įtvirtintais
        reikalavimais.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas, pateikdamas Paraišką, supranta, kad Kredito davėjas tvarkys jo asmens duomenis tiek, kiek
        būtina Sutarties sudarymo ir vykdymo, kontrolės, tapatybės nustatymo, asmens duomenų teisingumo patikrinimo,
        kreditingumo vertinimo, įsiskolinimo valdymo, Kredito davėjo teisių ir teisėtų interesų apsaugos ir gynimo
        tikslais.
      </li>
      <li class="c13 li-bullet-1">
        Tuo atveju, kai Kredito gavėjas yra susituokęs / vedęs ir kai Vartojimo kreditas imamas šeimos poreikiams
        tenkinti, Kredito gavėjo sutuoktinis taip pat yra informuojamas Kredito davėjo pateikiamu pranešimu apie jo
        asmens duomenų tvarkymą. Toks Kredito gavėjo sutuoktinio informavimas bus atliekamas Kredito davėjo Tinklapyje
        nurodytais būdais, apie kuriuos Kredito gavėjas bus informuojamas iki Sutarties sudarymo.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjo tvarkomų asmens duomenų sąlygos ir tvarka yra nurodoma Kredito davėjo patvirtintoje Privatumo
        politikoje, su kuria Kredito gavėjas yra supažindinamas Paraiškos pateikimo metu.
      </li>
    </ol>
  </li>
  <li>
    
    <h3 class="title">BAIGIAMOSIOS NUOSTATOS</h3>
    <ol>
      <li class="c13 li-bullet-1">
        Sutartis įsigalioja nuo šios Sutarties sudarymo joje nustatyta tvarka momento ir galioja iki pilno Kredito
        gavėjo įsipareigojimų pagal šią Sutartį įvykdymo arba jos nutraukimo Sutartyje ar teisės aktuose nustatyta
        tvarka ir sąlygomis.
      </li>
      <li class="c13 li-bullet-1">
        Šalys atskiru susitarimu gali pakeisti ar papildyti šią sutartį. Ši sutartis gali būti pakeista ir/ar papildyta
        tik atskiru šalių susitarimu, išskyrus atvejus, kuomet (i) sutarties pakeitimas ir/ar papildymas yra būtinas
        pagal Lietuvos Respublikos teisės aktus; (ii) Kredito davėjo veiklą prižiūrinčių institucijų tiesiogiai ar
        netiesiogiai gautus nurodymus; (iii) kai tai būtina keičiant Vartojimo kredito teikimo technologinius
        sprendinius ar esant kitam pakankamam pagrindui, jei tokie pakeitimai neblogina Kredito gavėjo teisinės
        padėties. Tokio pobūdžio sutarties pakeitimai gali būti daromi vienašališkai Kredito davėjo, tačiau nesutinkanti
        su pakeitimais Kredito gavėjas turi teisę atsisakyti šios Sutarties, šioje Sutartyje ar teisės aktuose nustatyta
        tvarka ir terminais.
      </li>
      <li class="c13 li-bullet-1">
        Kredito davėjas turi teisę šios Sutarties vykdymui ir visoms ir/ar daliai šioje sutartyje nurodytų funkcijų,
        teisių bei įsipareigojimų vykdymui savo nuožiūra pasitelkti bet kokius trečiuosius asmenis, įskaitant, bet
        neapsiribojant Kredito davėjo grupės įmones, turinčius teisę šioje Sutartyje nustatyta tvarka rinkti, apdoroti
        ir/ar naudoti Kredito gavėjo ir asmens duomenis, ir/ar atlikti bet kokius kitus veiksmus ir funkcijas, kurios
        Kredito davėjo sprendimu yra perduodamos tretiesiems asmenims (įskaitant, bet neapsiribojant Kredito davėjo
        grupės įmones).
      </li>
      <li class="c13 li-bullet-1">
        Šalys įsipareigoja į visus klausimus atsakyti operatyviai (ne vėliau kaip per 7 (septynias) dienas), išsamiai ir
        tiksliai.
      </li>
      <li class="c13 li-bullet-1">
        Visi Kredito gavėjo pranešimai ar prašymai vykdant šią Sutartį gali būti pateikti Kredito davėjui el. paštu ar
        registruotu laišku Kredito davėjo adresais, nurodytais Specialiosiose sąlygose. Kredito davėjas visus pranešimus
        ar prašymus teikia Kredito gavėjui siųsdamas juos Specialiosiose sąlygose nurodytu elektroniniu paštu, ir (ar)
        trumpąja tekstine žinute (SMS), ir (ar) skambindamas Kredito gavėjo nurodytu mobiliojo telefono numeriu.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas įsipareigoja ne vėliau nei per 5 (penkias) kalendorines dienas raštu informuoti Kredito davėją
        apie: (i) bet kokius kontaktinės informacijos ir asmens duomenų pasikeitimus; (ii) staigų ekonominės padėties
        pablogėjimą, kiekvieną aplinkybę, dėl kurios sumažėja ar gali sumažėti Kredito gavėjo mokumas; (iii) apie
        Vartojimo kredito panaudojimą ne tam tikslui, kurį Kredito gavėjas nurodė Paraiškoje; (iv) kiekvieną aplinkybę,
        dėl kurios pasunkėja ar gali pasunkėti Kredito gavėjo galimybės tinkamai įvykdyti prisiimtus įsipareigojimus ir
        (ar) kiekvieną aplinkybę, kuri pablogina arba gali pabloginti Kredito gavėjo finansinę padėtį ateityje (pvz.
        naujus priimtus skolinius įsipareigojimus (naujos vartojimo sutartys, lizingas ir kt.); (v) apie bet kokį
        arbitražinį, teisminį ir/arba kitą procesą (vykdymo ir kt.), kuris yra inicijuotas prieš Kredito gavėją, jeigu
        tai gali pabloginti Kredito gavėjo finansinę padėtį.
      </li>
      <li class="c13 li-bullet-1">
        Kredito gavėjas negali perleisti savo įsipareigojimų pagal šią Sutartį be išankstinio Kredito davėjo sutikimo.
        Kredito davėjas turi teisę perleisti bet kokias iš Sutarties kylančias reikalavimo teises į Kredito gavėją
        kitoms Lietuvos finansų įstaigoms, kurios yra įtrauktos į Viešąjį vartojimo kredito davėjų sąrašą pagal Lietuvos
        banko reikalavimus, arba kitiems subjektams, kurie atitinka sąlygas, nustatytas Lietuvos Respublikos teisės
        aktuose. Kredito davėjas informuoja Kredito gavėja apie reikalavimo perleidimą, laikantis Lietuvos Respublikos
        teisės aktuose nustatytų reikalavimų.
      </li>
      <li class="c13 li-bullet-1">
        Kredito davėjas yra įtrauktas į Viešąjį vartojimo kredito davėjų sąrašą. Kredito davėjo veiklą prižiūri Lietuvos
        banko Finansų rinkų priežiūros tarnyba (Totorių g. 4, 01121, Vilnius, Lietuva, tel.: +370 800 50 500, el.
        paštas: info@lb.lt, interneto svetainė www.lb.lt).
      </li>
    </ol>
  </li>
</ol>


`;

export default file;
