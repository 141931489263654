import styled from '@emotion/styled'

export const FooterStyled = styled.footer`
  padding: 40px 20px 20px;
  border-top: 1px solid #E9E9E9;;
  color: #808080;
  background-color: #F3F3F3;
  .wrapper {
    width: 90%;
    margin: 0 auto;
  }

  .column-1, .column-2 {
    margin-bottom: 24px;
  }
  
  .more-menu {
    padding: 0;
    font-size: 13px;
    line-height: 21px;
    list-style-type: none;
  }
  
  .paragraph {
    font-size: 13px;
    line-height: 19px;
  }
  
  .more-menu a {
    color: #808080;
    text-decoration: none;
  }
  
  .more-menu a:hover {
    text-decoration: underline;
  }
  
  .title {
    font-weight: normal;
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 21px;
    color: #000;
    text-transform: uppercase;
    min-height: 30px;
  }
  
  @media (min-width: 768px) {
    border: none;

    .column-2 {
      background: url(./assets/images/map-dark.png.webp) no-repeat 40px 55px;
      background-size: 300px 142px;
    }

    .column-2, .column-3 {
      border-left: 1px solid rgba(0,0,0,.2);
      padding-left: 35px;
    }
    .title {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 23px;
    }
    .paragraph {
      font-size: 13px;
      line-height: 21px;
    }
  }
  @media (min-width: 992px) {
    padding: 60px;
  }
`