import styled from "@emotion/styled";

export const Subtitle = styled.p`
  margin-top: 30px;
  strong {
    font-weight: bold;
  }
  a {
    color: #333;
    font-size: 16px;
  }
`;
