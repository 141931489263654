import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const ErrorLabel = styled.div`
  color: red;
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
`;
