import styled from "@emotion/styled";

export const Container = styled.div`
  flex: 1;
`;
export const SliderWrapper = styled.div`
  .slider {
    background: #d0d4d7;
    height: 18px;
    position: relative;
    border-radius: 4px;
  }
`;
export const MinMaxValuesContainer = styled.div`
  z-index: 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #ccc;
  margin-top: 6px;
`;

export const SliderTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #000;
  font-weight: normal;
  padding-top: 4px;
`;
export const InputSuffix = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
  z-index: 10;
  color: ${(props) => (props.isDragged ? "#3f8efc" : "")};
  font-weight: ${(props) => (props.isDragged ? "bold" : "normal")};
`;

export const Thumb = styled.button`
  background: #3f8efc;
  height: 36px;
  width: 21px;
  border-radius: 4px;
  border: 0;
  box-shadow: 1px 2px 4px 0px #999;

  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`;

export const Track = styled.div`
  background-color: #52cae9;
  height: 18px;
  border-radius: 4px;
`;
export const InputAndSliderTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
`;

export const ThumbContainer = styled.div`
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  outline: none;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Input = styled.input`
  max-width: 100px;
  padding: 10px ${(props) => (props.suffixLength ? `calc(${props.suffixLength}ch + 20px)` : "10px")} 10px 0;
  font-size: 18px;
  text-align: right;
  background-color: #fff;
  z-index: 10;
  appearance: none;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  position: relative;
  color: ${(props) => (props.isDragged ? "#3f8efc" : "")};
  font-weight: ${(props) => (props.isDragged ? "bold" : "normal")};

  :read-only {
    border: none;
    padding-right: 0;
    line-height: 22px;
  }

  :hover {
    cursor: inherit;
  }
  :active,
  :focus-visible {
    border: 1px solid #bbb;
    outline: 0;
  }
`;
