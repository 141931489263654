import { Wrapper } from "./NotFound.styles";
import translations from "../localizations/index";
import Layout from "../containers/Layout";
import useTitle from "../hooks/useTitle";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  useTitle(translations.pages.notFound.documentTitle);

  const handleOnClick = () => {
    navigate("/");
  };

  return (
    <Layout>
      <Wrapper>
        <div className="inner-wrapper">
          <div>
            <img src="./assets/images/oops.svg" alt="oops-illustration" />
            <h3 className="title">{translations.pages.notFound.title}</h3>
            <p className="subtitle">{translations.pages.notFound.subtitle}</p>
            <button onClick={handleOnClick} className="btn btn-primary">
              {translations.pages.notFound.buttonText}
            </button>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
}

export default NotFound;
