import styled from "@emotion/styled";

export const HeaderStyled = styled.header`
  left: 0;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  padding: 25px 15px;

  .logo {
    max-width: 200px;
    max-height: 52px;
  }

  .cta-btn {
    display: none;
  }

  @media (min-width: 576px) {
    .cta-btn {
      display: block;
      color: #fff;
      background-color: transparent;
      border-radius: 30px;
      padding: 13px 26px;
      border: 2px solid;
      text-decoration: none;

      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 1.5em;
      transition: background-color 0.25s, border-color 0.25s;
      &:hover {
        border-color: rgb(85, 193, 249);
        background-color: rgb(85, 193, 249);
      }
    }
  }
`;
