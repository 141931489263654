import PropTypes from "prop-types";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Layout({ children, theme }) {
  return (
    <>
      <Header theme={theme} />
      {children}
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
