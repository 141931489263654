const file = `
<h3 class="page-title">PRIVATUMO POLITIKA</h3>
<p class="center">Įsigalioja: 2022 m. spalio 7 d.</p>
<p class="center">Atnaujinta: 2022 m. gruodžio 27 d.</p>
<p>
  UAB "REGO.LT“ (toliau – <strong>Įmonė</strong>) yra Jūsų pateiktų asmens duomenų valdytoja, kuri užtikrina, kad asmens
  duomenys Įmonėje būtų tvarkomi laikantis duomenų valdytojams taikomų asmens duomenų apsaugos reikalavimų. Ši Privatumo
  politika taikoma tais atvejais, kai per internetinę svetainę www.rego.lt (toliau – <strong>Svetainė</strong>)
  pateikiate mums savo asmens duomenis.
</p>
<p>
  Šioje Privatumo politikoje mes aprašome, kaip tvarkome Jūsų duomenis, kai naudojatės mūsų internetine svetaine.
  Naudodamiesi internetine svetaine, Jūs sutinkate su informacijos rinkimu ir naudojimu laikantis šioje Privatumo
  politikoje nustatytomis taisyklėmis.
</p>
<p>
  Atkreipkite dėmesį, kad ši Privatumo politika taikoma tik šiai internetinei svetainei. Šioje internetinėje svetainėje
  gali būti nuorodų į kitas mūsų nenaudojamas ar nekontroliuojamas internetines svetaines. Tokios nuorodos nereiškia,
  kad patvirtiname ar peržiūrėjome tokias trečiųjų šalių internetines svetaines.
</p>
<p>Ši Privatumo politika parengta vadovaujantis šiais teisės aktais:</p>
<ol>
  <li>
    2016 m. balandžio 27 d. Europos Parlamento ir Tarybos reglamentu (ES) 2016/679 dėl fizinių asmenų apsaugos tvarkant
    asmens duomenis ir dėl laisvo tokių duomenų judėjimo ir kuriuo panaikinama Direktyva 95/46/EB (Bendrasis duomenų
    apsaugos reglamentas) (toliau –
    <a href="https://eur-lex.europa.eu/legal-content/LT/TXT/?uri=CELEX%3A32016R0679" target="_blank">BDAR</a>);
  </li>
  <li>
    Lietuvos Respublikos asmens teisinės apsaugos įstatymu (toliau –
    <a href="https://www.e-tar.lt/portal/lt/legalActEditions/TAR.5368B592234C?faces-redirect=true" target="_blank"
      >ADTAĮ</a
    >);
  </li>
  <li>
    Lietuvos Respublikos elektroninių ryšių įstatymu (toliau –
    <a href="https://www.e-tar.lt/portal/lt/legalAct/TAR.82D8168D3049/asr" target="_blank">ERĮ</a>);
  </li>
  <li>
    Kitais taikomais teisės aktais, kiek tai susiję su Jūsų asmens duomenų tvarkymu šioje Politikoje nustatytais
    tikslais.
  </li>
</ol>
<p>
  Tvarkydama asmens duomenis Įmonė vadovaujasi BDAR, ADTAĮ, ERĮ, taip pat kituose teisės aktuose, reglamentuojančiuose
  asmens duomenų tvarkymą ir apsaugą, įtvirtintais reikalavimais.
</p>

<h3 class="paragraph-title">I. Informacija apie Įmonę:</h3>
<p>
  UAB "REGO.LT“<br />
  Juridinio asmens kodas: 305864279<br />
  Buveinės adresas: Savanorių pr. 1-217, Vilnius<br />
  El. paštas: <a href="mailto:info@rego.lt">info@rego.lt</a> <br />
  Tel. +370 614 40466<br />
</p>

<h3 class="paragraph-title">II. Informacija apie Įmonės duomenų apsaugos pareigūną</h3>
<p>
  Su asmens duomenų apsaugos klausimais prašome kreiptis į Įmonėje paskirtą duomenų apsaugos pareigūną el. pašto adresu
  <a href="mailto:dpo@rego.lt">dpo@rego.lt.</a>
</p>
<h3 class="paragraph-title">III. Kokią informaciją apie Jus renkame?</h3>
<p>Renkame ir naudojame toliau nurodytą informaciją:</p>

<ol>
  <li>
    <i>Asmens duomenys</i><br /><br />
    Kai naudojatės mūsų interneto svetaine, mes galime rinkti tam tikrą asmeninę informaciją apie jus (toliau – Asmens
    duomenys), t. y. kai jūs savanoriškai pateikiate tokią informaciją, pvz., kai naudojatės tam tikromis mūsų
    paslaugomis, susisiekiate su mumis ir kt. Kai renkame Asmens duomenis, mes pateikiame nuorodą į šią Privatumo
    politiką.<br /><br />
  </li>
  <li>
    <i>Naudojimo duomenys</i><br /><br />
    Mes taip pat galime rinkti informaciją, kurią Jūsų naršyklė pateikia, kai apsilankote internetinėje svetainėje
    (toliau – Naudojimo duomenys). Tai gali būti informacija, susijusi su Jūsų IP (interneto protokolo) adresu,
    naršyklės ir įrenginio tipu, informacija apie tai, kokias internetines svetaines lankėte prieš tai ir kiti
    identifikatoriai, susiję su Jūsų įrenginiu.
    <br /><br />Kai naudojatės internetine svetaine mobiliuoju įrenginiu, Naudojimo duomenys gali apimti tokią
    informaciją kaip, pvz., naudojamo mobiliojo įrenginio tipas, mobiliojo įrenginio unikalus ID, mobiliojo įrenginio IP
    adresas, operacinė sistema, naudojamos interneto naršyklės tipas, unikalus įrenginio identifikatorius ir kita
    diagnostiniai duomenys. <br /><br />Atkreipkite dėmesį, kad tokia informacija, kuri renkama pasyviai naudojant
    įvairias technologijas, negali būti naudojama konkrečiai identifikuoti Jus. Internetinė svetainė gali naudoti tokią
    informaciją ir susieti ją su kita informacija, kad galėtų stebėti, pavyzdžiui, bendrą internetinės svetainės
    lankytojų skaičių, lankytojų skaičių kiekviename internetinės svetainės puslapyje ir pan.<br /><br />
  </li>
  <li>
    <i>Slapukų duomenys</i><br /><br />
    Internetinėje svetainėje naudojami slapukai, kad galėtume stebėti veiklą internetinėje svetainėje ir laikyti tam
    tikrą informaciją. Daugiau informacijos apie tai rasite mūsų <a href="/slapuku-politika">Slapukų politikoje</a>.
  </li>
</ol>

<h3 class="paragraph-title">
  IV. Renkami ir tvarkomi Asmens duomenys, jų tvarkymo tikslais ir teisėti pagrindai, taip pat Asmens duomenų saugojimo
  terminai:
</h3>

<table>
  <thead>
    <th>Asmens duomenų tvarkymo tikslai</th>
    <th>Renkami ir tvarkomi Asmens duomenys</th>
    <th>Teisėto Asmens duomenų tvarkymo pagrindai</th>
    <th>Asmens duomenų saugojimo terminai</th>
  </thead>
  <tbody>
    <tr>
      <td>
        Kreditingumo vertinimo tikslais, t. y. siekiant įvertinti pateiktą paraišką ir priimti sprendimą dėl sutarties
        sudarymo
      </td>
      <td class="text-align-left">
        <ul>
          <li>vardas;</li>
          <li>pavardė;</li>
          <li>asmens kodas;</li>
          <li>išsilavinimas;</li>
          <li>duomenys apie darbą (darbovietė, pareigos);</li>
          <li>pageidaujama kredito suma ir terminas;</li>
          <li>informacija apie šeiminę padėtį bei nepilnamečius vaikus;</li>
          <li>informacija, ar kreditas imamas šeimos ar asmeniniams poreikiams;</li>
          <li>kredito reitingas;</li>
          <li>
            finansiniai duomenys (pajamų šaltiniai, rūšis ir sumos, esami įsipareigojimai (jų rūšys ir sumos, kreditorių
            pavadinimai), asmeninės ir (arba) namų ūkio išlaidos, banko duomenys, finansinių operacijų istorija);
          </li>
          <li>
            duomenys apie savalaikį ir tinkamą finansinių įsipareigojimų nevykdymą, t. y. informacija apie nepadengtus
            įsiskolinimus bei jų mokėjimo istoriją;
          </li>
          <li>
            duomenys apie esamus (buvusius) finansinius įsipareigojimus ir jų vykdymą, jų rūšys ir sumos, vykdymo
            terminai, kreditorių pavadinimai;
          </li>
          <li>išlaidos per mėnesį;</li>
          <li>
            duomenys apie sutuoktinį (vardas, pavardė, asmens kodas, sutuoktinio įsipareigojimai finansų įstaigoms);
          </li>
          <li>
            sprendimo dėl kredito išdavimo ir paskolos sutarties sudarymo dokumentų kopijos (pasas, asmens tapatybės
            kortelė, mokesčių deklaracija, banko sąskaitos išrašas, darbo sutartis ir kt.);
          </li>
          <li>ieškomų asmenų registro informacija;</li>
          <li>negaliojančių dokumentų registro informacija;</li>
          <li>duomenys apie neveiksnumą ar veiksnumo apribojimą.</li>
        </ul>
      </td>
      <td>Asmens duomenys tvarkomi Jūsų duoto sutikimo pagrindu (Reglamento 6 straipsnio 1 dalies a punktas).</td>
      <td>
        Asmens duomenys saugomi 1 (vienus) metus nuo sutikimo davimo dienos (jeigu buvo sudaryta kredito sutartis –
        asmens duomenys saugomi 10 (dešimt) metų nuo sutarties (finansinių įsipareigojimų) įvykdymo dienos.
      </td>
    </tr>
    <tr>
      <td>Kredito sutarties sudarymo, vykdymo ir apskaitos tikslais</td>
      <td class="text-align-left">
        <ul>
          <li>vardas;</li>
          <li>pavardė;</li>
          <li>asmens kodas;</li>
          <li>gyvenamosios vietos adresas;</li>
          <li>telefono ryšio numeris;</li>
          <li>el. pašto adresas;</li>
          <li>banko sąskaitos numeris;</li>
          <li>
            kredito suma, įmokų terminai, grąžinimo data, paskirtis, palūkanų norma, kita su kreditu susijusi
            informacija;
          </li>
          <li>
            informacija apie įkeičiamą nekilnojamąjį turtą (unikalus kodas, adresas, turto savininko duomenys (vardas,
            pavardė, asmens kodas), turto vertė);
          </li>
          <li>informacija apie laiduotoją (vardas, pavardė, asmens kodas, gyvenamosios vietos adresas).</li>
        </ul>
      </td>
      <td>
        Asmens duomenys tvarkomi siekiant sudaryti sutartį su duomenų subjektu (Reglamento 6 straipsnio 1 dalies b
        punktas).
      </td>
      <td>Asmens duomenys saugomi 10 (dešimt) metų nuo sutarties (finansinių įsipareigojimų) įvykdymo dienos.</td>
    </tr>
    <tr>
      <td>
        Siekiant įvykdyti taikomus pinigų plovimo ir (ar) teroristų finansavimo prevencijos reikalavimus, įskaitant ir
        tarptautinių sankcijų vykdymo užtikrinimą
      </td>
      <td class="text-align-left">
        <ul>
          <li>vardas;</li>
          <li>pavardė;</li>
          <li>asmens kodas;</li>
          <li>gyvenamosios vietos adresas;</li>
          <li>pilietybė;</li>
          <li>
            asmens tapatybės dokumento duomenys (asmens dokumento pavadinimas, numeris, išdavimo data, galiojimo data,
            dokumentą išdavusi valstybė);
          </li>
          <li>leidimo gyventi Lietuvos Respublikoje numeris ir galiojimo laikas, jo išdavimo vieta ir data;</li>
          <li>telefono ryšio numeris;</li>
          <li>el. pašto adresas;</li>
          <li>
            informaciją, ar klientas yra politiškai pažeidžiamas (paveikiamas) asmuo ir užimamos viešosios pareigos
            (valstybė, institucija);
          </li>
          <li>pagrindinis pajamų šaltinis;</li>
          <li>pagrindinė veiklos (darbo, verslo ar profesinės veiklos) sritis;</li>
          <li>pagrindiniai veiklos regionai;</li>
          <li>informacija, ar kredito lėšomis ketina naudotis pats, ar veikia trečiojo asmens naudai ar interesais;</li>
          <li>kredito lėšų naudojimo tikslas;</li>
          <li>
            duomenys apie tikrąjį naudos gavėją (vardas, pavardė, asmens kodas (jeigu nėra asmens kodo – gimimo data),
            asmens tapatybės dokumento duomenys, pilietybė, informacija, ar tai politiškai pažeidžiamas (paveikiamas)
            asmuo).
          </li>
        </ul>
      </td>
      <td>
        Asmens duomenys tvarkomi siekiant įvykdyti duomenų valdytojui taikomą teisinę prievolę (Reglamento 6 straipsnio
        1 dalies c punktas).
      </td>
      <td>
        Asmens duomenys saugomi Lietuvos Respublikos pinigų plovimo ir teroristų finansavimo prevencijos įstatyme
        nustatytais terminais, tačiau ne ilgiau nei 10 metų.
      </td>
    </tr>
    <tr>
      <td>Įsiskolinimo valdymo ir/ar išieškojimo tikslais</td>
      <td class="text-align-left">
        <ul>
          <li>vardas;</li>
          <li>pavardė;</li>
          <li>asmens kodas;</li>
          <li>gyvenamosios vietos adresas;</li>
          <li>
            duomenys apie turtą: turimas kilnojamasis ir nekilnojamasis turtas, turtinės teisės bei jų suvaržymai;
          </li>
          <li>
            finansiniai duomenys (pajamų šaltiniai, rūšis ir sumos, esami įsipareigojimai (jų rūšys ir sumos, kreditorių
            pavadinimai), asmeninės ir (arba) namų ūkio išlaidos, banko duomenys, finansinių operacijų istorija).
          </li>
        </ul>
      </td>
      <td>
        Asmens duomenys tvarkomi siekiant teisėtų Bendrovės interesų (Reglamento 6 straipsnio 1 dalies f punktas).
      </td>
      <td>Asmens duomenys saugomi 10 (dešimt) metų nuo sutarties įvykdymo dienos.</td>
    </tr>
    <tr>
      <td>Tiesioginės rinkodaros tikslais</td>
      <td class="text-align-left">
        <ul>
          <li>vardas;</li>
          <li>pavardė;</li>
          <li>el. pašto adresas;</li>
          <li>tel. ryšio numeris.</li>
        </ul>
      </td>
      <td>Asmens duomenys tvarkomi Jūsų duoto sutikimo pagrindu (BDAR 6 straipsnio 1 dalies a punktas).</td>
      <td>
        Sutikimas dėl tiesioginės rinkodaros galioja 5 metus nuo jo davimo dienos arba iki tol, kol asmuo jį atšaukia.
        Asmens duomenys saugomi sutikimo galiojimo laikotarpiu ir 2 metus po sutikimo galiojimo pabaigos (archyvavimo
        tikslais).
      </td>
    </tr>
  </tbody>
</table>
<p>
  Asmens duomenis naudojame tikslais, nurodytais šioje Privatumo politikoje. Jeigu pateikiate Asmens duomenis tam tikru
  tikslu, tai mes galime naudoti šiuos duomenis remiantis tikslu, dėl kurio tokie duomenys buvo pateikti. Pavyzdžiui,
  jei susisiekiate su mumis el. paštu, mes naudojame jūsų pateiktus Asmens duomenis tam, kad atsakytume į Jūsų klausimą
  arba išspręstumėte problemą. Jeigu pateikiate Asmens duomenis tam, kad galėtumėte naudotis tam tikromis Svetainės
  paslaugomis, mes naudosime Jūsų Asmens duomenis tam, kad suteiktume jums prieigą prie tokių paslaugų ir stebėsime Jūsų
  naudojimąsi tokiomis paslaugomis.
</p>

<h3 class="paragraph-title">V. Kaip Jūsų Asmens duomenis tvarkome tiesioginės rinkodaros tikslais?</h3>
<p>
  Tiesioginė rinkodara – tai veikla, kurios tikslas paštu, telefonu arba kitokiu tiesioginiu būdu siūlyti asmenims
  prekes ar paslaugas ir (arba) teirautis jų nuomonės dėl siūlomų prekių ar paslaugų (ADTAĮ 2 str. 1 d.).
</p>
<p>
  Informuojame, kad Jums sutikus su Asmens duomenų tvarkymu tiesioginės rinkodaros tikslais, Įmonės toks Asmens duomenų
  tvarkymas apima pasiūlymų, naujienų apie teikiamas paslaugas, informacijos apie vykstančius renginius, akcijas ar
  kitos informacijos teikimą, taip pat teiravimąsi Jūsų nuomonės apie teikiamas paslaugas tiek el. paštu, tiek telefonu
  (skambučiu ir/ar SMS žinute).
</p>
<p>
  Informuojame, kad vadovaujantis ERĮ 69 str. 2 d., Įmonė, gaudama iš Jūsų elektroninio pašto kontaktinius duomenis
  (pvz., kai perkate prekes iš mūsų Svetainės), gali naudoti šiuos kontaktinius duomenis savo pačios panašių paslaugų
  rinkodarai, jei Jums yra suteikiama aiški, nemokama ir lengvai įgyvendinama galimybė nesutikti arba atsisakyti tokio
  kontaktinių duomenų naudojimo pirmiau nurodytais tikslais, kai šie duomenys yra renkami ir, jei Jūs iš pradžių
  neprieštaravote dėl tokio duomenų naudojimo, siunčiant kiekvieną žinutę.
  <u
    >Todėl Jūs turite galimybę nesutikti su Jūsų pateikto el. pašto naudojimu Įmonės panašių prekių rinkodarai
    parašydami Įmonei el.paštu
  </u>
  <a href="mailto:dpo@rego.lt">dpo@rego.lt</a>.
</p>
<p>
  Jūs galite bet kuriuo metu nesutikti, kad Asmens duomenys būtų tvarkomi tiesioginės rinkodaros tikslais, įskaitant
  profiliavimą. Atsisakyti mūsų tiesioginės rinkodaros pranešimų galite:
</p>
<ul>
  <li>kai gaunate pranešimą el. paštu - paspaudę, tam skirtą nuorodą mūsų siunčiamų pranešimų apačioje;</li>
  <li>gavę SMS pranešimą – paskambindami tel. +370 614 40466;</li>
  <li>gavę skambutį – atsisakydami tiesioginės rinkodaros skambučio metu;</li>
  <li>
    visais kitais atvejais atsisakyti tiesioginės rinkodaros galite parašę mums el. paštu:
    <a href="mailto:dpo@rego.lt">dpo@rego.lt</a>.
  </li>
</ul>

<h3 class="paragraph-title">VI. Ar Jūsų atžvilgiu vykdome automatizuotą sprendimų priėmimą, įskaitant profiliavimą?</h3>

<p>
  Siekdama įgyvendinti atsakingo skolinimo taisykles, Įmonė, vertindama Jūsų kreditingumą, gali priimti automatizuotus
  sprendimus, t. y. įvertinti, ar Jums galima suteikti kreditą. Tokie sprendimai atliekami naudojantis surinkta
  informacija iš Jūsų bei įvairių duomenų bazių (registrų). Kadangi yra nustatomas Jūsų kreditingumo reitingas, jo
  pagrindu gali būti priimtas sprendimas dėl kredito suteikimo. Jūs turite teisę nesutikti, kad tokiam vertinimui būtų
  taikomas automatizuotas sprendimo priėmimas (jeigu dėl to buvo atsisakyta Jums suteikti kreditą).
</p>
<p>
  Siekdama įgyvendinti pinigų plovimo ir teroristų finansavimo prevencijos reikalavimų laikymąsi, Įmonė gali vykdyti
  profiliavimą priskiriant Jus rizikos kategorijai (pvz., kliento rizikai, šalies (geografinio regiono) rizikai,
  paslaugų ar operacijų rizikai ir kt. Nuo rizikos kategorijos gali skirtis apie Jus surenkamų duomenų kiekis,
  stebėsenos dažnumas ir pan.
</p>

<h3 class="paragraph-title">VII. Ar privalote mums pateikti Asmens duomenis?</h3>
<p>
  Informuojame, kad norėdami, jog suteiktume Jums šioje Politikoje nurodytas paslaugas (kaip numatyta šios Politikos IV
  skyriuje), Jūs privalote mums pateikti nurodytus Asmens duomenis.
</p>
<p>
  Jūs galite pasirinkti, ar sutikti su Asmens duomenų tvarkymu tiesioginės rinkodaros tikslais, tačiau jei pageidausite
  gauti tokius tiesioginės rinkodaros pranešimus, turite sutikti pateikti nurodytus Asmens duomenis.
</p>

<h3 class="paragraph-title">VIII. Iš kur gauname Jūsų duomenis (kokia duomenų kilmė)?</h3>

<p>
  Asmens duomenis gauname tiesiogiai iš Jūsų, t. y. Jums pateikiant savo Asmens duomenis. Jūsų Asmens duomenis taip pat
  galime gauti iš kredito tarpininko UAB „LENDERS“, juridinio asmens kodas 302996838, buveinės adresas: J. Galvydžio g.
  5, LT-08236, Vilnius.
</p>
<p>
  Įmonė, siekdama visapusiškai įvertinti Jūsų galimybes vykdyti prisiimtus finansinius įsipareigojimus, taip pat
  vykdydama sutartį, tam tikrą asmeninę informaciją apie Jus gali gauti iš trečiųjų asmenų, t. y.
</p>
<ul>
  <li>
    VĮ Registrų centro, kitų valstybinių registrų – vardą, pavardę, asmens dokumentus, gyvenamąją vietą, šeiminę padėtį,
    nepilnamečių vaikų skaičių, duomenis apie Jums priklausantį turtą, ieškomų asmenų registro informacija,
    negaliojančių dokumentų registro informacija, duomenys apie neveiksnumą ar veiksnumo apribojimą, daiktinių teisių
    suvaržymus ar apribojimus, duomenys apie sutuoktinį (vardą, pavardę, asmens kodą);
  </li>
  <li>Valstybinio socialinio draudimo fondo valdybos – duomenis apie Jūsų pajamas ir darbovietę;</li>
  <li>Valstybinės mokesčių inspekcijos – duomenis apie mokesčių mokėtojus;</li>
  <li>
    UAB „Scorify“ – Jūsų sudarytų sutarčių kiekis, datos, rūšis, Jūsų finansiniai įsipareigojimai, jų vykdymo terminai,
    pradelstų mokėjimų sumos, terminai, kiekis, paraiškų rūšys, sumos ir kita susijusi informacija;
  </li>
  <li>
    Lietuvos banko tvarkomos Paskolų rizikos duomenų bazės (PRDB) – duomenys apie paskolos gavėjus ir jiems suteiktas
    paskolas.
  </li>
</ul>

<h3 class="paragraph-title">IX. Kam teikiame Jūsų asmens duomenis?</h3>
<p>
  Informacija, kurią pateikiate mums, įskaitant Asmens duomenis, gali būti perkelta į kompiuterius, esančius už jūsų
  valstybės jurisdikcijos ribų, kur duomenų apsaugos įstatymai gali skirtis nuo jūsų jurisdikcijos įstatymų. Bet kuriuo
  atveju Įmonė Jūsų asmens duomenų neperduoda į trečiąsias valstybes ar tarptautines organizacijas, t. y. už Europos
  Sąjungos ar Europos ekonominės erdvės ribų, išskyrus atvejus, jeigu tai įpareigotų atlikti įstatymai.
</p>
<p>
  Jūsų asmens duomenys gali būti perduodami teisėtą interesą turintiems tretiesiems asmenims, t. y. UAB „Scorify“ (įm.
  k. 302423183). Asmens duomenys taip pat perduodami Bendrovės įmonių grupei, darbuotojams, Bendrovės duomenų
  tvarkytojams (pvz., įmonėms, teikiančioms serverių nuomos, duomenų saugojimo, svetainės administravimo, IT ūkio
  priežiūros, rinkodaros paslaugas teikiančioms įmonėms ir pan.) ir kt. Duomenų tvarkytojai asmens duomenis tvarko tik
  pagal Bendrovės nurodymus ir teisės aktų reikalavimus. Jūsų asmens duomenys nėra perduodami į trečiąją valstybę arba
  tarptautinei organizacijai.
</p>
<p>
  Taip pat informuojame, kad Jums nevykdant ar netinkamai vykdant savo finansinių įsipareigojimų Bendrovei, Bendrovė
  turi teisę perduoti informaciją apie Jus UAB „Scorify“, Lietuvos banko administruojamai Paskolų rizikos duomenų bazei
  (PRDB), taip pat antstoliams, advokatams, o esant poreikiui – perleisti iš kredito sutarčių kylančius reikalavimus ar
  perduoti vykdyti išieškojimą skolos išieškojimo įmonėms.
</p>
<p>Informuojame, kad mes nesidalinsime Jūsų Asmens duomenimis su trečiaisiais asmenimis be Jūsų leidimo.</p>

<h3 class="paragraph-title">X. Jūsų kaip duomenų subjektų teisės</h3>
<p>Pateikdami savo Asmens duomenis per mūsų Svetainę ir vadovaujantis BDAR, jūs turite šias teises:</p>
<ol>
  <li>Teisę gauti informaciją apie duomenų tvarkymą (BDAR 13 ir 14 straipsniai).</li>
  <li>Teisę susipažinti su savo asmens duomenimis, įskaitant teisę į asmens duomenų kopiją (BDAR 15 straipsnis).</li>
  <li>Teisę reikalauti ištaisyti savo asmens duomenis, jeigu jie yra netikslūs (BDAR 16 straipsnis).</li>
  <li>
    Teisę reikalauti ištrinti savo asmens duomenis („teisė būti pamirštam“) (BDAR 17 straipsnis). Ši teisė įgyvendinama
    tik jeigu yra tenkinama bent viena BDAR 17 straipsnio 1 dalyje nurodytų sąlygų.
  </li>
  <li>
    Teisę reikalauti apriboti savo asmens duomenų tvarkymą (BDAR 18 straipsnis). Ši teisė įgyvendinama tik jeigu yra
    tenkinama bent viena BDAR 18 straipsnio 1 dalyje nurodytų sąlygų.
  </li>
  <li>
    Teisę į duomenų perkeliamumą (BDAR 20 straipsnis). Ši teisė įgyvendinama tik jeigu Asmens duomenys yra tvarkomi
    sutikimo pagrindu ir automatizuotomis priemonėmis (BDAR 20 straipsnio 1 dalies a ir b punktai).
  </li>
  <li>Teisę nesutikti su Asmens duomenų tvarkymu (BDAR 21 straipsnis).</li>
</ol>
<p>
  Informuojame, kad nesutikdami su Įmonės sprendimu, priimtu dėl Jūsų pateikto prašymo, susijusio su Jūsų, kaip duomenų
  subjekto teisių įgyvendinimu, Jūs turite teisę pateikti skundą Valstybinei duomenų apsaugos inspekcijai (daugiau
  informacijos <a href="https://vdai.lrv.lt/">https://vdai.lrv.lt/</a>).
</p>
<p>
  Jūs taip pat turite teisę bet kuriuo metu atšaukti savo sutikimą (jei tokį davėte) tvarkyti Jūsų asmens duomenis
  tiesioginės rinkodaros tikslais. Savo sutikimą galite bet kada atsiimti, pateikdami laisvos formos prašymą el. laišku
  adresu <a href="mailto:dpo@rego.lt">dpo@rego.lt</a>.
  <u
    >Sutikimo atšaukimas neturi įtakos Įmonės Asmens duomenų tvarkymo, pagrįsto sutikimu iki jo atsiėmimo, teisėtumui,
    t. y. Asmens duomenų tvarkymas nurodytais tikslais bus laikomas teisėtu iki sutikimo atšaukimo pareiškimo
    momento.</u
  >
</p>

<h3 class="paragraph-title">XI. Kaip turite kreiptis dėl Jūsų teisių įgyvendinimo?</h3>
<p>
  <u
    >Kad galėtumėte įgyvendinti aukščiau paminėtas teises, turite pateikti mums prašymą raštu ir tokiu būdu, kuriuo
    galėtume Jus identifikuoti, t. y. patvirtinant savo tapatybę. Prašymai dėl teisių įgyvendinimo, nesant galimybės
    nustatyti prašymą teikiančio asmens tapatybę, Įmonėje nėra nagrinėjami.</u
  >
</p>
<p>Visi pranešimai, susiję su asmens duomenų tvarkymu, yra pateikiami:</p>
<ol>
  <li>el. paštu <a href="mailto:dpo@rego.lt">dpo@rego.lt</a>;</li>
  <li>paštu adresu: Savanorių pr. 1-217, Vilnius.</li>
</ol>
<p>
  Jūsų prašymai, susiję su duomenų subjektų teisių įgyvendinimu, nagrinėjami ne ilgiau kaip 1 (vieną) mėnesį.
  Atsižvelgiant į prašymo sudėtingumą ir gautų prašymų skaičių šis laikotarpis Įmonės gali būti pratęstas 2 (dviem)
  mėnesiams. Apie termino pratęsimą Įmonė visais atvejais Jus informuos.
</p>
<p>Reikalavimai prašymui, kai jie teikiami raštu:</p>
<ol>
  <li>prašymas turi būti įskaitomas;</li>
  <li>prašymas turi būti pasirašytas;</li>
  <li>prašyme turi būti nurodytas Jūsų vardas, pavardė, gyvenamoji vieta, kontaktinė informacija ryšiui palaikyti;</li>
  <li>
    prašyme turi būti nurodyta, kokia teise norite pasinaudoti ir, kai taikoma, tokį prašymą pagrindžiantys argumentai
    ar dokumentai;
  </li>
  <li>
    jei prašymą Jūsų vardu teikia atstovas, prašyme turi būti nurodyti ne tik aukščiau nurodyti duomenys, bet taip pat
    Jūsų atstovo jo vardas, pavardė, gyvenamoji vieta, kontaktinė informacija ryšiui palaikyti.
  </li>
</ol>
<p>Jūsų tapatybės patvirtinimo būdai:</p>
<ol>
  <li>
    Teikiant prašymą tiesiogiai atvykus į Įmonės biurą: pateikiant asmens tapatybę patvirtinantį dokumentą Įmonės
    darbuotojui;
  </li>
  <li>
    Teikiant prašymą paštu: kartu su prašymu turi būti pateikta asmens tapatybę patvirtinančio dokumento kopija,
    patvirtinta notaro, arba šio dokumento kopija, patvirtinta kita teisės aktų nustatyta tvarka;
  </li>
  <li>
    Teikiant prašymą elektroniniu paštu. Tokiu būdu pateikti prašymai turi būti pasirašyti kvalifikuotu elektroniniu
    parašu arba suformuotas elektroninėmis priemonėmis, kurios leidžia užtikrinti teksto vientisumą ir nepakeičiamumą
    (išskyrus atvejus, kai atšaukiate duotą sutikimą tvarkyti asmens duomenis šioje Politikoje nustatyta tvarka).
    <br />
    <br />
    Nuskenuotos pasirašytų prašymų kopijos ar skundai, pateikti elektroninio pašto pranešimu, jų nepasirašant
    kvalifikuotu elektroniniu parašu, neatitinka teisės aktuose nustatytų reikalavimų. Toks prašymo pateikimas gali būti
    kliūtis priimti nagrinėti Jūsų prašymą, todėl tuo atveju, jei neturite kvalifikuoto elektroninio parašo, prašymą
    turėtumėte pateikti paštu arba tiesiogiai atvykti į Įmonės biurą.
  </li>
</ol>
<p>
  Teikiant prašymą per atstovą, prie prašymo turi būti papildomai pateikiamas atstovavimą patvirtinantis dokumentas ar
  jo kopija, patvirtinta teisės aktų nustatyta tvarka.
</p>

<h3 class="paragraph-title">XII. Politikos pakeitimai</h3>
<p>
  Įmonė turi teisę pakeisti ar papildyti šios Privatumo politikos sąlygas. Apie pakeitimus Įmonė praneša Svetainėje
  nurodant atnaujintą Politikos datą.
</p>
<p>
  Patariame periodiškai peržiūrėti šią Privatumo politiką dėl įvykusių pakeitimų. Ši Privatumo politika paskutinį kartą
  buvo atnaujinta viršuje nurodytą dieną.
</p>

`;

export default file;
