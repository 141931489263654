import styled from "@emotion/styled";

export const CheckboxWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (min-width: 768px) {
    border: none;
  }
`;

export const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  margin-left: 10px;
`;

export const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
