const file = `
<h3 class="page-title">UAB "Rego.lt" tinklalapio naudojimosi ir paslaugų teikimo taisyklės</h3>

<p class="center">2022-10-01 Nr. 001</p>
<p>1. VARTOJAMOS SĄVOKOS<br>
1.1. Bendrovė - UAB „Rego.lt“, įmonės kodas 305864279, registracijos adresas Savanorių pr.1, Vilnius (toliau – Kredito davėjas)<br>
1.2. Tinklapis – internetinis tinklapis, kurio adresas yra www.rego.lt<br>
1.3. Taisyklės – šios tinklapio naudojimosi ir paslaugų teikimo taisyklės, skelbiamos viešai tinklapyje, kurios nustato naudojimosi Tinklapiu bei jame teikiamomis paslaugomis tvarką ir sąlygas.<br>
1.4. Asmens dokumentas – asmens tapatybę patvirtinantis dokumentas. Asmens dokumentais laikomi: asmens tapatybės kortelė, pasas, leidimas gyventi Lietuvoje.<br>
1.5. Dokumentai – Paskolos sutartis Informacijos Gavėjui forma bei kiti su vartojimo kredito išdavimu susiję dokumentai.<br>
1.6. Atstovas – Kredito davėjas darbuotojas arba įgaliotas fizinis ar juridinis asmuo, kuris veikia Kredito davėjo vardu ir interesais.<br>
1.7. Paslaugos – visos Tinklapyje teikiamos paslaugos, tiek mokamos, tiek nemokamos.<br>
1.8. Paskolos paraiška – informacijos pateikimo forma Tinklapyje, kur pateikiami asmeniniai Kliento duomenys bei pageidaujamos gauti finansinės paslaugos kriterijai.<br>
1.9. Paskolos sutartis – tarp Kredito davėjo ir Kliento sudaryta Paskolos sutartis, kurios pagrindu Klientas įgyja teisę gauti pinigus Paskolos sutartyje numatytomis sąlygomis, pinigus grąžinant Kredito davėjui dalimis, Paskolos sutartyje numatytomis sąlygomis.
1.10. Paskolos suma – pinigų suma, šių Taisyklių bei Paskolos sutarties nustatyta tvarka Kredito davėjo pervedama į Kliento nurodytą banko sąskaitą.<br>
Šių Taisyklių straipsnių pavadinimai pateikti tam, kad būtų patogiau naudotis. Jie neturi įtakos Taisyklių sąlygų aiškinimui.<br>
Pirmiau aptartos šių Taisyklių sąvokos toliau tekste rašomos pirmosiomis didžiosiomis raidėmis, jeigu pagal kontekstą jų reikšmė nesiskiria nuo apibrėžtosios šiame skyriuje.
</p>
<p>2. BENDROSIOS NUOSTATOS<br>
2.1. Taisyklės yra sutartis, sudaroma tarp Kredito davėjo ir Kliento dėl naudojimosi Tinklapiu ir Paslaugomis. Šių Taisyklių prasme naudojamasis Tinklapiu apima bet kokių Kliento veiksmų atlikimą prisijungus prie Tinklapio interneto ryšio pagalba.<br>
2.2. Kiekvienas Tinklapio lankytojas privalo nedelsiant susipažinti su Taisyklėmis ir gali pradėti naudotis Tinklapiu, tik jeigu sutinka su visomis šių Taisyklių nuostatomis ir įsipareigoja jų laikytis.<br>
2.3. Klientas, nesutikdamas su šių Taisyklių nuostatomis arba jų nesuprasdamas, neturi teisės naudotis Tinklapiu.<br>
2.4. Klientas, pateikdamas paskolos Paraišką, užsakydamas Paslaugas ar kitaip naudodamasis Tinklapiu patvirtina, kad perskaitė ir suprato šias Taisykles ir patvirtina, kad šiose Taisyklėse nėra jam nesuprantamų ar paslėptų sąlygų.<br>
2.5. Visas Tinklapyje skelbiamas turinys ir informacija yra Kredito davėjo nuosavybė ir negali būti kopijuojama ar kitaip naudojama be išankstinio Kredito davėjo sutikimo.
</p>
<p>3. NAUDOJIMOSI TINKLAPIU TVARKA<br>
3.1. Klientas įsipareigoja užtikrinti, kad visa naudojantis Tinklapiu Kliento pateikiama informacija ir duomenys, įskaitant formų ir komentarų Tinklapyje pildymą, failų pateikimą ir el. Laiškus:<br>
3.1.1. nėra netikra, klaidinanti ar neteisinga;<br>
3.1.2. nepažeidžia Kredito davėjo ar jokių trečiųjų asmenų teisių ir interesų;<br>
3.1.3. nepažeidžia teisės aktų reikalavimų;<br>
3.1.4. neprieštarauja viešajai tvarkai;<br>
3.1.5. nėra rasistinio, skatinančio smurtą ar neapykantą, įžeidžiančio ar draudžiamo pobūdžio;<br>
3.2. Draudžiama naudojantis Tinklapiu ir jo Paslaugomis vykdyti neteisėtą veiklą, įskaitant, bet neapsiribojant:<br>
3.2.1. sąmoningai teikti klaidinančią ar neteisingą informaciją ir duomenis;<br>
3.2.2. siekti sudaryti sandorius ne savo vardu;<br>
3.2.3. neteisėtiems veiksmas ar sandoriams vykdyti;<br>
3.3. Klientas supranta ir sutinka, kad jam pažeidus 3.1-3.2. punktų reikalavimus, Kredito davėjas turi teisę vienašaliu sprendimu be atskiro perspėjimo blokuoti Kliento paskyrą Tinklapyje ir/ar sustabdyti Paslaugų teikimą, negrąžinant Klientui už suteiktas Paslaugas sumokėtų pinigų, jeigu buvo naudojamasi Papildomomis paslaugomis.<br>
3.4. Naudodamasis Tinklapiu ir jame teikiamomis Paslaugomis Klientas patvirtina, kad:<br>
3.4.1. jis yra pilnametis ir veiksnus fizinis asmuo, arba juridinis asmuo, turintis visus įgaliojimus ir teises naudotis Tinklapio Paslaugomis ir vykdyti bet kokius įstatymų leidžiamus sandorius;<br>
3.4.2. supranta, jog jam sutikus su šiomis Taisyklėmis, tarp Kliento ir Kredito davėjo sukuriami tik tokie teisiniai santykiai, kuriuos tiesiogiai ir aiškiai nustato šios Taisyklės;<br>
3.4.3. supranta ir sutinka, kad tuo atveju, jei Kliento elektroninės bankininkystės prisijungimo duomenys taps žinomi tretiesiems asmenims, tokie tretieji asmenys gali prisiimti įsipareigojimus, kurie taps privalomais Klientui ir įsipareigoja tokius įsipareigojimus prisiimti ir tinkamai vykdyti.<br>
3.4.4. supranta, jog Paraiškos pateikimas ar Papildomų paslaugų užsakymas bei apmokėjimas negarantuoja paskolos gavimo ar kitokios Kliento pageidaujamos finansinės paslaugos suteikimo, nes tai priklauso nuo Kredito davėjo mokumo ir rizikos įvertinimo taisyklių ir Kliento įvertinimo rezultatų;<br>
3.4.6. supranta, kad Kredito davėjas neprivalo tikrinti Kliento pateiktų duomenų tikrumo;<br>
3.4.7. supranta, kad Kredito davėjas neprivalo vykdyti mokumo ir rizikos įvertinimo pas visus atstovaujamus Kredito davėjus, o tik pas tuos, kurių keliami reikalavimai Klientui yra tenkinami pagal Kliento pateiktus duomenis;<br>
3.4.8. supranta ir sutinka, kad Kredito davėjas turi teisę nevykdyti mokumo ir rizikos įvertinimo Kredito davėjų sistemose, jeigu Kliento pateikti duomenys yra neteisingi arba jeigu Klientas netinkamai patvirtino savo asmens tapatybę;<br>
3.4.9. supranta, kad Kredito davėjas negrąžins Klientui už kokybiškai suteiktas Paslaugas sumokėtų pinigų, nepriklausomai nuo to, kad Klientas galimai negavo to rezultato, kurio tikėjosi naudodamasis Tinklapiu ar jame teikiamomis Paslaugomis.
</p>
<p>4. KLIENTO ANKETOS PATVIRTINIMAS<br>
4.1. Kredito davėjas naudoja šiuos būdus Kliento anketos patvirtinimui:<br>
4.1.1. Mobilus parašas (ipasas.lt), suteikiantis galimybę Klientui patvirtinti Kliento anketą naudojant mobilųjį telefoną;<br>
4.1.2. “Smart-ID” nemokama programėle (smart-id.com), kurios pagalba Klientas gali patvirtinti Kliento anketą naudojantis mobiliuoju telefonu, kuriame yra įdiegta ši programėlė;<br>
4.1.3. “Instantor” tarpininkų paslauga (instantor.com), suteikiančia galimybę patvirtinti Kliento anketą prisijungiant per savo interneto banką;<br>
4.1.4. 0,01 ct (vieno euro cento) bankinio mokėjimo pavedimas į Bendrovės banko sąskaitą, atliekant šį mokėjimo pavedimą nurodant bankui pateikti gavėjui mokėtojo asmens kodą bei bankinio mokėjimo paskirtyje nurodant unikalų Kliento anketos (PDF formate) SHA-1 kodą.<br>
</p>
<p>5. PASLAUGŲ TEIKIMO TVARKA IR SĄLYGOS<br>
5.1. Tinklapyje www.rego.lt Klientams suteikiama galimybė užpildyti paskolos paraišką (toliau – Paraiška), kurios pagrindu Kredito davėjas turi teisę, bet ne pareigą, atlikti Kliento mokumo ir rizikos įvertinimus visose atstovaujamose kredito bendrovėse, ir naudotis kitomis Tinklapyje teikiamomis Paslaugomis šiose Taisyklėse nustatyta tvarka.<br>
5.2. Paskolos paraiška teikiama internetu Klientui teisingai užpildant visus paraiškos formos laukus ir paspaudžiant mygtuką “Siųsti užklausą”.<br>
5.3. Pateikus Paskolos paraišką, Klientui yra sugeneruojama Kliento anketa PDF formatu pagal pateiktus asmeninius duomenis.<br>
5.4. Pasiūlymas sudaryti Paskolos sutartį pateikiamas Klientui tik atlikus jo mokumo ir rizikos įvertinimą, o mokumo ir rizikos įvertinimas gali būti atliekamas tik identifikavus Klientą ir Klientui tinkamai patvirtinus Kliento anketą.<br>
5.5. Kliento anketa laikoma patvirtinta tinkamai, jeigu Paskolos paraiškoje pateikti asmens duomenys sutampa su identifikaciją atlikusio (vienu iš Taisyklių 4.1. punkte nurodytu būdu) asmens duomenimis.<br>
5.6. Tais atvejais, kai Klientas Paskolos paraiškos pildymo metu pasirenka, jog nori vertintis kartu su sutuoktiniu, yra vertinamos Kliento sutuoktinio pajamos ir finansiniai įsipareigojimai. Kliento sutuoktinis yra atskirai informuojamas apie jo asmens duomenų tvarkymą.<br>
</p>
<p>6. PAGRINDINĖS PASLAUGOS TEIKIMO SĄLYGOS<br>
6.1. 0,01 EUR sumokėjimas (tuo atveju, jeigu identifikacija atliekama Taisyklių 4.1.4. Punkto pagrindu) nėra laikomas mokesčiu už paslaugas, o yra naudojamas tik kaip priemonė gauti Kliento anketos patvirtinimą bei papildoma priemonė prie Kliento tapatybės nustatymo, todėl šis mokėjimo pavedimas nesuteikia Klientui teisės gauti Papildomas Kredito davėjo paslaugas, gauti sąskaitą-faktūrą ar kreiptis dėl šios sumos grąžinimo.<br>
6.2. Bendrovė atlieka automatinį Kliento mokumo ir rizikos įvertinimą ir teikia Kredito davėjų pasiūlymus sudaryti Paskolos sutartis remiantis automatinio įvertinimo rezultatais.<br>
Išimtinais atvejais mokumo ir rizikos vertinimas atliekamas rankiniu būdu.
</p>
<p>7. KLIENTO TEISĖS IR PAREIGOS<br>
7.1. Klientas turi teisę gauti tarpininkavimo paslaugas (pateikiant jam tinkamiausią pasiūlymą sudaryti Paskolos sutartį) pilna apimtimi ir kokybe visiškai NEMOKAMAI, jeigu laikosi šių Taisyklių nuostatų, nustatytos paslaugų teikimo tvarkos ir atitinka Kredito davėjų keliamus reikalavimus.<br>
7.2. Klientas turi teisę užsisakyti papildomas mokamas Paslaugas, sumokėdamas jų mokestį Taisyklėse nustatyta tvarka. Paslaugos mokesčio sumokėjimas neatleidžia kliento nuo paslaugų teikimo tvarkos laikymosi.<br>
7.3. Klientas privalo pateikti reikalingą informaciją bei duomenis ir atlikti paslaugų teikimo tvarkoje numatytus Kliento veiksmus, jeigu nori gauti kokybišką paslaugą, nepriklausomai nuo to ar paslauga teikiama mokamai ar nemokamai.<br>
7.4. Klientas turi teisę atgauti sumokėtą Paslaugos mokestį tik jeigu pateikė teisingą ir visą privalomą informaciją ar duomenis, laiku ir tinkamai atliko visus būtinus Kliento veiksmus bei laikėsi kitų Taisyklių nuostatų, o Bendrovė nesuteikė numatytų mokamų Paslaugų.<br>
7.5. Pateikdamas Paraišką Klientas papildomai patvirtina, kad: a) iki Paraiškos pateikimo dienos nėra Klientui žinomų aplinkybių, dėl kurių ateityje gali mažėti Kliento (šeimos) Paraiškoje nurodytas pajamų per mėnesį dydis; b) iki Paraiškos pateikimo dienos nėra Klientui žinomų aplinkybių, kurios gali sukelti sunkumų Klientui ateityje tinkamai vykdyti prisiimtus įsipareigojimus; <br>
7.6. Užpildydamas ir pateikdamas Paraišką ar bet kokią kitą informaciją Tinklapyje, Klientas prisiima visą atsakomybę už jos turinio atitikimą šių Taisyklių sąlygoms ir teisės aktų reikalavimams.<br>
</p>
<p>8. BENDROVĖS TEISĖS IR PAREIGOS<br>
8.1. Kredito davėjas turi teisę bet kuriuo metu savo nuožiūra vienašališkai ir be atskiro įspėjimo apriboti ar nutraukti Kliento galimybę naudotis Tinklapiu, anuliuoti Kliento pateiktą Paraišką ar panaikinti paskyrą bei uždrausti Klientui iš naujo registruotis Tinklapyje, jeigu Klientas šiurkščiai pažeidė šias Taisykles ar nesilaiko jos esminių nuostatų. Apie sankcijų pritaikymą Klientas gali būti informuojamas registracijos metu nurodytu elektroninio pašto adresu. Kredito davėjas pritaikytos sankcijos neatleidžia Kliento nuo atsakomybės už jo veiksmų neatitikimą teisės aktų reikalavimams. Taisyklių pažeidimo atveju už Paslaugas Kliento sumokėti pinigai Klientui nėra grąžinami.
8.2. Kredito davėjas turi teisę (bet ne pareigą) bet kada stebėti Kliento veiksmus Tinklapyje, įskaitant Kliento pateiktų Paraiškų ar paskelbtų komentarų stebėjimą. Naudodamas teisėtas priemones Kredito davėjas turi teisę tirti bet kokius Taisyklių pažeidimus.
8.3. Kredito davėjas turi teisę gauti atlyginimą iš Kliento už suteiktas Paslaugas pagal Tinklapyje nurodytas kainas, galiojančias Paslaugų suteikimo metu, jeigu Klientas pasirinko gauti mokamas Paslaugas. Klientui pareikalavus Kredito davėjas  pateiks jam sąskaitą-faktūrą už suteiktas ir Kliento apmokėtas Paslaugas.
8.4. Kredito davėjas turi teisę bet kuriuo metu be išankstinio Klientų informavimo laikinai sustabdyti ar visiškai nutraukti Tinklapio veiklą.
</p>
<p>9. ATSAKOMYBĖS RIBOJIMAS<br>
9.1. Paskolos sutarties su Klientu parengimas nėra besąlygiškas Kredito davėjo įsipareigojimas suteikti Paskolą. Paskolos sutartis įsigalioja Paskolos sutartyje numatyta tvarka ir tik tuo atveju, jei Kredito davėjas perveda Paskolos sumą į Gavėjo nurodytą sąskaitą. Kredito davėjui per 10 (dešimt) dienų nepervedus Paskolos sumos nuo Paskolos sutarties sudarymo datos, laikoma, jog Paskolos sutartis neįsigaliojo.<br>
9.2. Paskolos sutarties pasirašymas Klientui nesukuria jokių įsipareigojimų, kol Kredito davėjas pagal sutartį neperveda Paskolos sumos. Paskolos sutartis įsigalioja tik abiems šalims ją tinkamai patvirtinus/pasirašius ir Kredito davėjui pervedus Paskolos sumą į Kliento banko sąskaitą.<br>
9.3. Kredito davėjo neatsako už Paraiškoje pateikiamos Kliento informacijos neatitikimą tikrovei ir jos turinį. Visą su tuo susijusią atsakomybę prisiimą šią informaciją pateikęs Klientas.<br>
9.4. Kredito davėjas nėra atsakingas už Tinklapio ir jo Paslaugų teikimo sutrikimus, dėl kurių Klientas ar tretieji asmenys patyrė žalą, jeigu šie sutrikimai atsirado ne dėl Kredito davėjo kaltės. <br>
9.5. Kredito davėjas negarantuoja, kad Klientas gaus jo Paraiškoje nurodytą pageidaujamą finansinę paslaugą bei neprivalo atlyginti Klientui jokių dėl to atsiradusių nuostolių, įskaitant už užsakytas mokamas Paslaugas sumokėtos kainos grąžinimą.<br>
9.6. Klientas supranta ir sutinka, kad Kredito davėjas niekada ir dėl jokių priežasčių nebus atsakinga už bet kokius Kliento ar trečiųjų asmenų galimus nuostolius, susijusius su Kliento naudojimusi Tinklapiu ir/ar Paslaugomis ir šių Taisyklių laikymusi. Klientas prisiima visą atsakomybę dėl bet kokio pobūdžio žalos ar nuostolių atlyginimo, kurie jam ar tretiesiems asmenims gali atsirasti bet kokiu būdu Klientui naudojantis Tinklapiu, Paslaugomis ar jose esančia informacija.<br>
</p>
<p>10. ASMENS DUOMENYS<br>
10.1. Tvarkydama asmens duomenis Kredito davėjas vadovaujasi 2016 m. balandžio 27 d. Europos Parlamento ir Tarybos reglamentu (ES) 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir dėl laisvo tokių duomenų judėjimo ir kuriuo panaikinama Direktyva 95/46/EB (Bendrasis duomenų apsaugos reglamentas), Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymu, taip pat kituose teisės aktuose, reglamentuojančiuose asmens duomenų tvarkymą ir apsaugą, įtvirtintais reikalavimais;<br>
10.2. Kredito davėjo Privatumo politika pateikiama adresu: https://www.rego.lt/privatumo-politika/. Pateikdamas Paraišką, su šia Privatumo politika Klientas taip pat susipažįsta pažymėdamas tai varnele;<br>
10.3. Klientas supranta, kad Tinklapyje yra naudojami slapukai (angl. cookies), reikalingi svetainės funkcijų optimizavimui ir Klientui aktualaus turinio pateikimui ir naudodamasis Tinklapiu patvirtina savo sutikimą dėl slapukų naudojimo. Slapukų naudojimą Klientas gali reguliuoti pakeisdamas interneto naršyklės nustatymus.<br>
</p>
<p>11. BAIGIAMOSIOS NUOSTATOS<br>
11.1. Šios Taisyklės įsigalioja nuo 2022 m. spalio mėn. 10 d. ir galioja iki jų atšaukimo ar naujos versijos paskelbimo Tinklapyje datos.<br>
11.2. Kredito davėjas turi teisę be atskiro įspėjimo vienašališkai keisti bet kuriuos šių Taisyklių punktus ir nuostatas, paskelbdama apie tai Tinklapyje. Jeigu pasikeitus, pataisius ar papildžius Taisykles, Klientas toliau naudosis Tinklapiu arba teikiamomis Paslaugomis, tai automatiškai reikš Kliento sutikimą su Taisyklių pakeitimais ir įsipareigojimą jų laikytis.<br>
11.3. Visi ginčai tarp Kredito davėjo ir Kliento sprendžiami geranoriškai derybų kelių, o nepavykus susitarti – kompetentingame teisme pagal Kredito davėjo buveinės adresą.<br>
11.4. Visi pranešimai ir informacija, susijusi su naudojimosi Tinklapiu www.rego.lt ir Paslaugomis, Klientui bus siunčiami elektroninėmis priemonėmis, Paraiškoje nurodytais el. pašto adresu bei mobilaus telefono numeriu, iš Kredito davėjui priklausančių mobilaus telefono numerių bei elektroninio pašto adreso, kurie skelbiami Tinklapyje.<br>
11.5. Taisyklių pakeitimai įsigalioja nuo jų paskelbimo Tinklapyje momento
</p>
`;

export default file;
