const file = `
<h3 class="page-title">SLAPUKŲ POLITIKA</h3>
<p class="center">Atnaujinta: 2023 m. gruodžio 10 d.</p>
<h3 class="paragraph-title">I. Kas yra slapukai?</h3>
<p>Kad ši svetainė tinkamai veiktų, į Jūsų įrenginį gali būti įrašomos mažos duomenų rinkmenos, vadinamos slapukais. Slapukas – tai nedidelis failas, atsiųstas į įrenginį, naudotojui besilankant atitinkamoje interneto svetainėje. Slapukų pagalba interneto svetainė atpažįsta naudotojo įrenginį (pvz., naudotojui kitą kartą apsilankius šioje interneto svetainėje).</p>
<p><u>Slapukų informacija Svetainėje nėra naudojama asmeninei informacijai apie naudotoją rinkti.</u></p>

<h3 class="paragraph-title">II. Kokius slapukus naudojame Svetainėje?</h3>
<table>
  <thead>
    <th>Slapuko pavadinimas</th>
    <th>Paskirtis</th>
    <th>Trukmė</th>
  </thead>
  <tbody>
  <tr>
    <td>_gat</td>
    <td rowspan='3'>Analizės slapukai. Slapukai skirti informacijai rinkti ir ataskaitai apie Svetainės naudojimo statistiką sistemoje „Google“ pateikti, asmeniškai neidentifikuojant atskirų lankytojų („Google Analytics“ paslauga).</td>
    <td>1 minutė</td>
  </tr>
  <tr>
    <td>_ga</td>
    <td>2 metai</td>
  </tr>
  <tr>
    <td>_gid</td>
    <td>24 valandos</td>
  </tr>
  </tbody>
</table>

<h3 class="paragraph-title">III. Trečiųjų šalių slapukai</h3>
<p>Trečiųjų šalių slapukai yra sukuriami kitų internetinių svetainių. Šioms svetainėms priklauso tam tikras Svetainėje rodomas turinys, pvz., skelbimai ar vaizdai. Kai kuriuose mūsų Svetainės puslapiuose rodomas išorės paslaugų teikėjų, pvz., „Facebook“, „Google“ turinys. Kad naudotojai galėtų pamatyti tokį trečiųjų šalių turinį, pirmiausia jie turi sutikti su konkrečiomis jų sąlygomis. Į šias sąlygas patenka ir tų išorės paslaugų teikėjų slapukų politika, kuriai mes neturime jokios įtakos. Tačiau, jeigu lankytojai šio turinio nežiūri, jų įrenginiuose neišsaugomi jokie trečiųjų šalių slapukai.</p>

<h3 class="paragraph-title">IV. Informavimas apie slapukų naudojimą</h3>
<p>Apsilankant internetinėje svetainėje pirmą kartą Jūs esate informuojami, jog svetainėje naudojami slapukai. Iškylančiame lange Jūs turite galimybę iš karto susipažinti su slapukų politika paspaudę atitinkamą nuorodą.</p>

<h3 class="paragraph-title">V. Slapukų valdymas</h3>
<p>Slapukus galite valdyti ir (arba) ištrinti pagal savo pageidavimus. Galite ištrinti visus jūsų kompiuteryje jau esančius slapukus, o daugumoje naršyklių galite nustatyti, kad slapukai nebūtų įrašomi. Tačiau tokiu atveju kaskart, kai lankysitės svetainėje, Jums gali reikėti iš naujo nustatyti kai kurias parinktis, o kai kurios Svetainės funkcijos gali neveikti.
<br/><br/>Galite atsisakyti tik tų slapukų, kurie nėra būtini. Kai kurie slapukai būtini tam, kad galėtumėte naudotis šia svetaine. Be šių slapukų neveiks tam tikros funkcijos.
</p>

<h3 class="paragraph-title">VI. Slapukų naudojimo nustatymai interneto naršyklėse</h3>
<p>Priklausomai nuo naršyklės, kurią naudojate, gali būti įvairių būdų, kaip tinkamai pašalinti Svetainėje naudojamus slapukus. Toliau pateikiame nuorodas, kaip tai galima pasiekti visose pagrindinėse interneto naršyklėse:
<br/><a href="https://support.google.com/chrome/answer/95647" rel="noreferrer" target="_blank">Slapukų ištrynimas iš „Chrome“ naršyklės</a>
<br/><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US#w_delete-cookies-for-a-single-site" rel="noreferrer" target="_blank">Slapukų ištrynimas iš „Firefox“ naršyklės.</a>
<br/><a href="https://www.opera.com/ua/use-cases/clean-browser-and-remove-trackers" rel="noreferrer" target="_blank">Slapukų ištrynimas iš „Opera“ naršyklės</a>
<br/><a href="https://support.apple.com/en-gb/guide/safari/sfri47acf5d6/mac" rel="noreferrer" target="_blank">Slapukų ištrynimas iš „Safari“ naršyklės</a>
</p>

<h3 class="paragraph-title">VII. Pakeitimai</h3>
<p>Įmonė turi teisę pakeisti ar papildyti šios Slapukų politikos sąlygas. Apie pakeitimus Įmonė praneša Svetainėje nurodant atnaujintą Politikos datą.<br/><br/>
Patariame periodiškai peržiūrėti šią Slapukų politiką dėl įvykusių pakeitimų. Ši Slapukų politika paskutinį kartą buvo atnaujinta politikos pradžioje nurodytą dieną.
</p>
`;

export default file;
