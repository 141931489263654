import { Section, Partners, FormWrapper, Hero, CallToAction, Title } from "./Home.styles";
import Layout from "../../containers/Layout";
import t from "../../localizations/index";
import useTitle from "../../hooks/useTitle";
import Accordion from "../../components/Accordion";
import Calculator from "../../components/Calculator";
import SubmitForm from "../../components/SubmitForm";
import ApplicationFormSubmitedView from "../../components/ApplicationFormSubmitedView";
import ServiceDisabledView from "../../components/ServiceDisabledView";
import { useState } from "react";

function Home() {
  useTitle(t.pages.home.documentTitle);
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);
  const [applicationFormSubmitted, setApplicationFormSubmitted] = useState(false);
  const [applicationValues, setApplicationValues] = useState({});
  const [hasSendError, setSendError] = useState(false);
  const [submiting, setSubmiting] = useState(false);

  const handleCalculatorSubmit = (values) => {
    setApplicationValues(values);
    setApplicationSubmitted(true);
  };

  const handleCtaClick = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleApplicationFormSubmit = async (values) => {
    setSubmiting(true);
    const { status } = await fetch("https://jiwctakkrh.execute-api.eu-north-1.amazonaws.com/dev/send", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...values,
        loanAmount: applicationValues.loan_amount,
        term: applicationValues.term,
        product: "regolt",
      }),
    })
      .then((response) => response.json())
      .catch((errors) => {
        console.log("send error", errors);
        setSendError(true);
      });

    if (status) {
      if (status === "success") {
        setSendError(false);
      } else {
        setSendError(true);
      }
    } else {
      setSendError(true);
    }
    setApplicationFormSubmitted(true);
    setSubmiting(false);
  };

  const returnView = () => {
    return <ServiceDisabledView />;
    return (
      <FormWrapper>
        {applicationFormSubmitted ? (
          <ApplicationFormSubmitedView hasSendError={hasSendError} />
        ) : (
          <>
            <h1 className="title">{t.pages.home.hero.title}</h1>
            {applicationSubmitted ? (
              <SubmitForm submiting={submiting} onSubmit={handleApplicationFormSubmit} />
            ) : (
              <>
                <p className="subtitle">{t.pages.home.hero.text}</p>

                <Calculator onSubmit={handleCalculatorSubmit} />
              </>
            )}
          </>
        )}
      </FormWrapper>
    );
  };

  return (
    <Layout>
      <Hero>
        <div className="wrapper">{returnView()}</div>
      </Hero>
      <Section>
        <div className="wrapper">
          <Title className="decorated black">{t.pages.home.lowerInterest.title}</Title>
          <p>
            <span dangerouslySetInnerHTML={{ __html: t.pages.home.lowerInterest.text }} />
          </p>
          <a className="btn btn-primary cta-btn" onClick={handleCtaClick} href="">
            {t.pages.home.lowerInterest.btnText}
          </a>
        </div>
      </Section>
      <Section className="easyLoan">
        <div className="wrapper">
          <Title className="title">{t.pages.home.easyLoan.title}</Title>
          <div className="row">
            <div className="col col-12 col-lg-4">
              <img alt="img" src="./assets/images/step1.png.webp" loading="lazy" />
              <h6 className="title">{t.pages.home.easyLoan.col1.title}</h6>
              <div className="text">{t.pages.home.easyLoan.col1.text}</div>
            </div>
            <div className="col col-12 col-lg-4">
              <img alt="img" src="./assets/images/step2.png.webp" loading="lazy" />
              <h6 className="title">{t.pages.home.easyLoan.col2.title}</h6>
              <div className="text">{t.pages.home.easyLoan.col2.text}</div>
            </div>
            <div className="col col-12 col-lg-4">
              <img alt="img" src="./assets/images/step3.png.webp" loading="lazy" />
              <h6 className="title">{t.pages.home.easyLoan.col3.title}</h6>
              <div className="text">{t.pages.home.easyLoan.col3.text}</div>
            </div>
          </div>
        </div>
      </Section>
      <Section className="moreThan">
        <div className="wrapper">
          <Title className="decorated black">{t.pages.home.moreThan.title}</Title>
          <div className="row">
            <div className="col col-12 col-lg-4">
              <img alt="img" src="/assets/images/like.png" />
              <h6 className="title">{t.pages.home.moreThan.col1.title}</h6>
              <div className="text">{t.pages.home.moreThan.col1.text}</div>
            </div>
            <div className="col col-12 col-lg-4">
              <img alt="img" src="/assets/images/like.png" />
              <h6 className="title">{t.pages.home.moreThan.col2.title}</h6>
              <div className="text">{t.pages.home.moreThan.col2.text}</div>
            </div>
            <div className="col col-12 col-lg-4">
              <img alt="img" src="/assets/images/like.png" />
              <h6 className="title">{t.pages.home.moreThan.col3.title}</h6>
              <div className="text">{t.pages.home.moreThan.col3.text}</div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="wrapper">
          <Title className="decorated">{t.pages.home.dataProtection.title}</Title>
          <p>
            <span dangerouslySetInnerHTML={{ __html: t.pages.home.dataProtection.text }} />
          </p>
          <div className="images">
            <a href="https://www.lb.lt/en/sfi-financial-market-participants/rego-lt-uab" target="_blank">
              <img alt="lietuvos-bankas-logo" src="/assets/images/1_LB.png" loading="lazy" />
            </a>
            <img alt="ada-logo" src="/assets/images/2_ADA.png" loading="lazy" />
            <img alt="ssl-logo" src="/assets/images/3_SSL.png.webp" loading="lazy" />
          </div>
        </div>
      </Section>
      <Section className="faq">
        <div className="wrapper">
          <Title className="decorated">{t.pages.home.faq.title}</Title>
          <Accordion list={t.pages.home.faq.faqList} id="faq-accordion" />
        </div>
      </Section>
      <Section>
        <div className="wrapper">
          <Title className="decorated">{t.pages.home.loanExample.title}</Title>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: t.pages.home.loanExample.text,
              }}
            />
          </p>
        </div>
      </Section>
      <CallToAction>
        <div className="wrapper">
          <Title>{t.pages.home.callToAction.title}</Title>
          <a className="btn btn-primary cta-btn" onClick={handleCtaClick} href="/">
            {t.pages.home.callToAction.btnText}
          </a>
        </div>
      </CallToAction>
    </Layout>
  );
}

export default Home;
